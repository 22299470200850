import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs';
import { RoleService } from '../services/role.service';

export const rightsGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  let roleService = inject(RoleService);
  // authService.getCookie().subscribe((user)=>{
  //   return user.role !== 'Пользователь';
  // });
  return authService.getCookie().pipe(
    map(user => roleService.isAtLeastManager(user.role)) // Map the user role to a boolean
  );
};

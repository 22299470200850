import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User } from '../interfaces/user';
import { DatePipe, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [DatePipe, NgClass, FormsModule],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
})
export class ClientsComponent {
  constructor(private apiService: ApiService) {
    apiService.getUserList().subscribe((data: User[]) => {
      this.users = data;
      this.usersSorted = this.users;
      this.loading = false;
      this.sort('verified');
    });
  }

  MAP_ROLE: { [key: string]: string } = {
    '': 'Ошибка',
    'client': 'Клиент',
    'manager': 'Менеджер',
    'provision': 'Снабжение',
    'owner': 'Владелец',
    'admin': 'Админ',
  };


  loading: boolean = true;
  users: User[] = [];
  usersSorted: User[] = [];
  searchWord: string = '';

  // Sorting properties
  sortField: string = 'created'; // Default sort field
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order
  search(){
    this.usersSorted = this.users.filter(item => {
      return Object.values(item).some(value =>
        String(value).toLowerCase().includes(this.searchWord.toLowerCase())
      )
    });
  }

  sort(field: string) {
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new field and default to ascending order
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.sortOrders();
  }

  sortOrders() {
    this.users.sort((a, b) => {
      const aValue = a[this.sortField as keyof User];
      const bValue = b[this.sortField as keyof User];
      // Handle undefined values for the sort field
      if (aValue === undefined && bValue === undefined) {
        return 0; // Both are undefined, consider equal
      }
      if (aValue === undefined) {
        return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
      }
      if (bValue === undefined) {
        return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
      }

      // Compare the primary sort values based on their types
      let comparison = 0;
       // For other fields, use the existing comparison logic
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        } else if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
          // Compare booleans: false < true
          comparison = (aValue === bValue) ? 0 : (aValue ? 1 : -1);
      }
      // Return the comparison result based on sort order
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  }


  checkOld(date: Date | undefined){
    if(!date){
      return false;
    }
    const ans = new Date().getTime() - new Date(date).getTime();
    return ans/1000/60/60/24/30 >= 3;
  }
}

<div class="white-page">
  @if (loading) {
  <h2>Загрузка данных</h2>
  } @else{
  <div class="border-radius">
    <table>
      <thead>
        <tr>
          <th>ФИО</th>
          <th>Телефон</th>
          <th>Почта</th>
          <th>Роль</th>
          <th>Лицо</th>
          <th>Форма</th>
          <th>Название фирмы</th>
          <th>ИНН</th>
          <th>Последний визит</th>
          <th>Скидка</th>
          <th>Статус</th>
          <th>Внести изменения</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ user?.name }}</td>
          <td>
            <a href="/user/{{ user?._id }}" class="number-text">{{ user?.phone }}</a>
          </td>
          <td>
            @if(!isAtLeastOwner){
              @if(!user?.mail){
                ----
              }
              {{ user?.mail }}
            }@else {
              <input [(ngModel)]="user!.mail">
            }
          </td>
          @if (isAtLeastOwner) {
          <td>
            <select [(ngModel)]="changeRole">
              <option value="client">Клиент</option>
              <option value="manager">Менеджер</option>
              <option value="provision">Снабжение</option>
              <option disabled selected [value]="user?.role">
                {{ MAP_ROLE[user!.role] }}
              </option>
            </select>
          </td>
          } @else{
          <td>{{ MAP_ROLE[user!.role] }}</td>
          }
          <td>{{ user?.person }}</td>
          <td>
            @if(!user?.company){
              ----
            }
            {{ user?.company }}
          </td>
          <td>
            @if(!user?.companyName){
              ----
            }
            {{ user?.companyName }}
          </td>
          <td>
            @if(!user?.inn){
            ----
            }
            {{ user?.inn }}
          </td>

          <td [ngClass]="{'red': checkOld(user?.lastSeen)}">
            @if(!user?.lastSeen){
            ----
            }
              {{ user?.lastSeen  | date:'dd.MM.yyyy' }}
          </td>
          <td>
            <span style="text-wrap: nowrap;"><input style='width: 60px;' [(ngModel)]="user!.discount">%</span>
          </td>
          <td>
            <select [(ngModel)]="verification">
              <option value="Подтверждён">Подтверждён</option>
              <option value="Неподтверждён">Неподтверждён</option>
            </select>
          </td>
          @if (isAtLeastOwner) {
          <td><button (click)="verifyUser()">Внести изменения</button></td>
          } @else {
          <td><button (click)="verifyUser()">Подтвердить статус</button></td>
          }
        </tr>
      </tbody>
    </table>
  </div>
  @if(isAtLeastOwner){
    <button class="delete-button" (click)="deleteUser()"> Удалить пользователя </button>
  }
  }
</div>

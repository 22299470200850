  <div [ngClass]="{scroll: (router.url==='/register' && (handset.isHandset$ | async))}">
  <mat-sidenav-container class="sidenav-container">
    <!-- SIDENAV -->

    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="(handset.isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(handset.isHandset$ | async) ? 'over' : 'side'"
      [opened]="false"
    >
      <mat-toolbar class="menu-text">Меню</mat-toolbar>
      <mat-nav-list class="nav-list">
        <a mat-list-item routerLink="/create" (click)="drawer.close()" 
          ><span class='list-item'>Сделать заказ</span></a
        >
        <a class='list-item' mat-list-item routerLink="/list" (click)="drawer.close()">
          <span class='list-item'>Журнал заказов</span>
        </a>
        <a class='list-item' mat-list-item routerLink="/support" (click)="drawer.close()">
          <span class='list-item'>Наши контакты</span>
        </a>
        @if (isAuthenticated) {<a
          class='list-item'
          mat-list-item
          routerLink="/logout"
          (click)="drawer.close()">
          <span class='list-item'>Выйти</span>
        </a> 
        } @else {<a
          class='list-item' 
          mat-list-item routerLink="/login" (click)="drawer.close()">
          <span class='list-item'>Войти</span>
        </a>
        }
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- TOP TOOLBAR-->

      <mat-toolbar class="topbar">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="handset.isHandset$ | async"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <a class="logo" routerLink="/about">
          <div style="display: inline-block;">
            <img src="../../assets/logo.svg" width="60px" height="60px" style="margin-top: 0px; vertical-align: bottom;">
          </div>
          <!-- <div style="display: inline-block; color: white; margin-left: 10px; vertical-align:baseline; padding-bottom: 14px;">ПЕРВАЯ ФАБРИКА <br>ФАСАДОВ</div> -->
          <div style="display: inline-block; color: white; margin-left: 10px; vertical-align:baseline; padding-bottom: 14px;">Первая Фабрика<br>Фасадов</div>
        </a>

        <span class="fill-space"></span>
        <div *ngIf="!(handset.isHandset$ | async)">
          <a class="button" mat-button routerLink="/create">Сделать заказ</a>
          <a class="button" mat-button routerLink="/list">Журнал заказов</a>
          <a class="button" mat-button routerLink="/support">Наши контакты</a>
           @if(isAuthenticated){<a class="button" mat-button routerLink="/logout"
          [ngClass]="{'rightmost-button': router.url==='/create'}">
          Выйти</a>}
          @else {
          <a class="button" mat-button routerLink="/login"
          [ngClass]="{'rightmost-button': router.url==='/create'}">
            Войти</a>
          }
        </div>
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- TRANSCLUSION -->  
  <ng-content></ng-content>
  
</div>
<div></div>

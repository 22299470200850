<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  <!-- <h3 #id123>123 456 test</h3> -->
  @if (loadingOrders || loadingUsers || errorMessage) {
    <h2>Загрузка данных</h2>
    <h2>{{errorMessage}}</h2>
  } @else{
    <button *ngIf="!isDisplaying" (click)="getCharts()">Отобразить графики</button>
  }
  <div style="width: 800px; margin: 0 0 0 100px;">
    <canvas #revenueChart></canvas>
  </div>
  <div style="width: 800px; margin: 50px 0 0 100px;">
    <canvas #ordersAmountChart></canvas>
  </div>
  <div style="width: 800px; margin: 50px 0 0 100px;">
    <canvas #newClientsChart></canvas>
  </div>
  <div style="width: 800px; margin: 50px 0 0 100px;">
    <canvas #recurringClientsChart></canvas>
  </div>
  <div style="width: 800px; margin: 50px 0 0 100px;">
    <canvas #reclamationChart></canvas>
  </div>
</div>

import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-personal-cabinet',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
  templateUrl: './personal-cabinet.component.html',
  styleUrl: './personal-cabinet.component.scss'
})
export class PersonalCabinetComponent {
 
  constructor(private apiService: ApiService, private authService: AuthService, private roleService: RoleService) {
    authService.getCookie().subscribe((user)=>{
      const role = user.role;
      this.isAuthorizedOwner = roleService.isAtLeastOwner(role);
      this.isAuthorizedManager = roleService.isAtLeastManager(role);
      console.log(user);
      if(user._id){
        this._id = user._id;
      }
      if(user.bitrixWorkID){
        this.bitrixWorkID = user.bitrixWorkID;
      }
    });
  }
  
  account = new FormGroup({
    password: new FormControl(''),
    passwordRepeat: new FormControl(''),
    phone: new FormControl(''),
  });


  isAuthorizedOwner: boolean = false;
  isAuthorizedManager: boolean = false;
  _id: string = '';
  errorMessage: string = '';
  goodMessage: string = '';
  bitrixWorkID: string = '';

  changeBitrixWorkID(){
    this.apiService.changeBitrixWorkID(this.bitrixWorkID).subscribe((data:any)=>{
      if(data && data.message){
        this.errorMessage = data.message;
      }
      this.authService.refreshCookie();
    });
  }

  onSubmit(): void {
    const password = this.account.value.password;
    const passwordRepeat = this.account.value.passwordRepeat;
    if (!password || !passwordRepeat || password !== passwordRepeat) {
      this.errorMessage = 'Пароли не совпадают';
      return;
    }
    this.errorMessage = '';
    this.goodMessage = '';
    if(this.isAuthorizedOwner){
      const phone = this.account.value.phone;
      if(!phone){
        this.errorMessage = 'Не введен номер телефона';
        return;
      }
      if(this.validatePhone(phone)){
          this.apiService.updateUserPasswordAdmin(password, phone).subscribe((data: any)=>{
            if ('message' in data) {
              if (!data.message.includes('успешно')) {
                this.errorMessage = data.message;
                return;
              }
            }
            //@ts-ignore
            this.goodMessage = data.message;
          });    
      }
    }else{
      this.apiService.updateUserPassword(password).subscribe((data)=>{
        //@ts-ignore
        this.goodMessage = data.message;
      });
    }
  }
  private validatePhone(phone: string) {
    const regex = /^(8|\+7)[0-9]{3}[0-9]{7}$/;
    if(regex.test(phone)){
      return true;
    }
    else{
      this.errorMessage = 'Формат телефона должен быть 89821234567 или +79821234567'
      return false;
    }
  }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { User } from '../../interfaces/user';
import { HandsetService } from '../../services/handset.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
  selector: 'app-register',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, AsyncPipe,MatFormFieldModule, MatInputModule, MatAutocompleteModule,ReactiveFormsModule, NgClass],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {

  SUBJECT_OF_RUSSIA = [
    "Республика Адыгея (Адыгея)",
    "Республика Алтай",
    "Республика Башкортостан",
    "Республика Бурятия",
    "Республика Дагестан",
    "Республика Ингушетия",
    "Кабардино-Балкарская Республика",
    "Республика Калмыкия",
    "Карачаево-Черкесская Республика",
    "Республика Карелия",
    "Республика Коми",
    "Республика Крым",
    "Республика Марий Эл",
    "Республика Мордовия",
    "Республика Саха (Якутия)",
    "Республика Северная Осетия – Алания",
    "Республика Татарстан (Татарстан)",
    "Республика Тыва",
    "Удмуртская Республика",
    "Республика Хакасия",
    "Чеченская Республика",
    "Чувашская Республика – Чувашия",
    "Алтайский край",
    "Забайкальский край",
    "Камчатский край",
    "Краснодарский край",
    "Красноярский край",
    "Пермский край",
    "Приморский край",
    "Ставропольский край",
    "Хабаровский край",
    "Амурская область",
    "Архангельская область",
    "Астраханская область",
    "Белгородская область",
    "Брянская область",
    "Владимирская область",
    "Волгоградская область",
    "Вологодская область",
    "Воронежская область",
    "Ивановская область",
    "Иркутская область",
    "Калининградская область",
    "Калужская область",
    "Кемеровская область",
    "Кировская область",
    "Костромская область",
    "Курганская область",
    "Курская область",
    "Ленинградская область",
    "Липецкая область",
    "Магаданская область",
    "Московская область",
    "Мурманская область",
    "Нижегородская область",
    "Новгородская область",
    "Новосибирская область",
    "Омская область",
    "Оренбургская область",
    "Орловская область",
    "Пензенская область",
    "Псковская область",
    "Ростовская область",
    "Рязанская область",
    "Самарская область",
    "Саратовская область",
    "Сахалинская область",
    "Свердловская область",
    "Смоленская область",
    "Тамбовская область",
    "Тверская область",
    "Томская область",
    "Тульская область",
    "Тюменская область",
    "Ульяновская область",
    "Челябинская область",
    "Ярославская область",
    "Город Москва",
    "Город Санкт-Петербург",
    "Город Севастополь",
    "Еврейская автономная область",
    "Ненецкий автономный округ",
    "Ханты-Мансийский автономный округ – Югра",
    "Чукотский автономный округ",
    "Ямало-Ненецкий автономный округ"
];


  errorMessage: string = '';
  account = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl(''),
    mail: new FormControl(''),
    password: new FormControl(''),
    passwordCheck: new FormControl(''),
    person: new FormControl('Розничный покупатель'),
    company: new FormControl('ИП'),
    companyName: new FormControl(''),
    parentID: new FormControl(''),
    inn: new FormControl(''),
    managerConfirmationID: new FormControl(''),
  });
  constructor(private apiService: ApiService, private router: Router, public handsetService: HandsetService) {}
  isSecretFieldVisible = false;
  loading: boolean = false;

  //@ts-ignore
  @ViewChild('subjectHTML') subjectHTML: ElementRef<HTMLInputElement>;
  previousSubject: string = '';
  myControlSubject = new FormControl('');
  filteredSubjects: string[] = [];
  subject: string = '';

  filterPVC(): void {
    const filterValueSubject = this.subjectHTML.nativeElement.value;
    this.filteredSubjects = this.SUBJECT_OF_RUSSIA.filter(o => o.toLowerCase().includes(filterValueSubject.toLowerCase()));
    this.previousSubject = filterValueSubject;
  }

  onSubmit(): void {
    const name = this.account.value.name;
    let phone = this.account.value.phone;
    const mail = this.account.value.mail;
    const password = this.account.value.password;
    const passwordCheck = this.account.value.passwordCheck;
    const person = this.account.value.person;
    const company = this.account.value.company;
    const parentID = this.account.value.parentID;
    let companyName = this.account.value.companyName;
    const inn = this.account.value.inn;
    const managerConfirmationID = this.account.value.managerConfirmationID;
    if (!name || !phone || !password || !passwordCheck || !person || !mail || !this.subject) {
      this.errorMessage = 'Заполните все обязательные поля';
      return;
    }
    if (person !== 'Розничный покупатель' && person !== 'Сотрудник производителя мебели') {
      if (!company || !companyName) {
        this.errorMessage = 'Заполните все обязательные поля';
        return;
      }
      if(company !== 'Физическое лицо' && !inn){
        this.errorMessage = 'Заполните все обязательные поля';
        return;
      }
    }
    if (password !== passwordCheck) {
      this.errorMessage = 'Пароли не совпадают';
      return;
    }
    if(!this.validatePhone(phone)){
      return;
    }
    if (mail && !this.validateMail(mail)){
      return;
    }
    if(person !== 'Розничный покупатель' && company !== 'Физическое лицо' && person !== 'Сотрудник производителя мебели'){
      if(typeof(inn) === 'string' && !this.validateCompanyINN(inn)){
        return;
      }
    }
    if(person == 'Сотрудник производителя мебели' && !parentID){
      this.errorMessage = 'Не указан ID начальника';
      return;
    }

    phone = phone.replaceAll('+7', '8');
    let user: User;
    if (person === 'Розничный покупатель') {
      user = {
        name,
        phone,
        person,
        role: 'client',
        password,
      };
    }
    else if(person == 'Сотрудник производителя мебели'){
      user = {
        name,
        phone,
        person,
        //@ts-ignore
        parentID: parentID?.trim(),
        role: 'client',
        password,
      };
    }
    else {
      user = {
        name,
        phone,
        person,
        //There is all kinds of checks for it to be not defined
        //@ts-ignore
        company,
        //@ts-ignore
        companyName,
        role: 'client',
        password,
      };
      if(company !== 'Физическое лицо'){
        //@ts-ignore
        user.inn = inn;
      }
    }
    if(mail){
      user.mail = mail;
    }else{
      user.mail = '';
    }
    if(managerConfirmationID){
      user.managerConfirmationID = managerConfirmationID;
    }
    user.subject = this.subject;
    this.errorMessage = '';
    this.loading = true;
    this.apiService.register(user).subscribe({
      error: (err) => {
        this.errorMessage = err.error.message;
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
        localStorage.setItem('register', '1');
        this.router.navigate(['/login']);
        //localstorage сообщения о создании аккаунта
      },
    });
  }
  private validatePhone(phone: string) {
    const regex = /^(8|\+7)[0-9]{3}[0-9]{7}$/;
    if(regex.test(phone)){
      return true;
    }
    else{
      this.errorMessage = 'Формат телефона должен быть 89821234567 или +79821234567'
      return false;
    }
  }
  private validateMail(mail: string) {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/;
    if(regex.test(mail)){
      return true;
    }
    else{
      this.errorMessage = 'Некорректный email'
      return false;
    }
  }
  private validateCompanyINN(inn: string) {
    const regex = /^[0-9]{10,12}$/;
    if((inn.length === 10 || inn.length === 12) && regex.test(inn)){
      return this.countINN(inn);
    }
    else{
      this.errorMessage = 'Формат ИНН 10 или 12 цифр без лишних символов (1234567890)'
      return false;
    }
  }
  private countINN(inn: string){
    let innParsed = [];
    const innLength = inn.length;
    for(let i = 0; i < innLength; i++){
      innParsed[i] = parseInt(inn[i],innLength);
    }
    if(innLength === 10){
      if((((2*innParsed[0]+4*innParsed[1]+10*innParsed[2]+3*innParsed[3]+5*innParsed[4]
        +9*innParsed[5]+4*innParsed[6]+6*innParsed[7]+8*innParsed[8])%11)%10)===innParsed[9]){
          return true;
      }else{
        this.errorMessage = 'Вероятно, в вашем ИНН ошибка';
        return false;
      }
    }else{
      if(((((7*innParsed[0]+2*innParsed[1]+4*innParsed[2]+10*innParsed[3]+3*innParsed[4]
        +5*innParsed[5]+9*innParsed[6]+4*innParsed[7]+6*innParsed[8]+8*innParsed[9])%11)%10)===innParsed[10])
      &&((((3*innParsed[0]+7*innParsed[1]+2*innParsed[2]+4*innParsed[3]+10*innParsed[4]
        +3*innParsed[5]+5*innParsed[6]+9*innParsed[7]+4*innParsed[8]+6*innParsed[9]
        +8*innParsed[10])%11)%10)===innParsed[11])){
          return true;
      }else{
        this.errorMessage = 'Вероятно, в вашем ИНН ошибка';
        return false;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { pointsHistory, User } from '../interfaces/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of, retry } from 'rxjs';
import { Options } from '../interfaces/options';
import { Price } from '../interfaces/price';
import { Order } from '../interfaces/order';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private baseUrl = environment.baseUrl+'/api/'; 
  
  private usersUrl = this.baseUrl + 'users';
  private authUrl = this.baseUrl + 'auth';  
  private optionsUrl = this.baseUrl + 'options';
  private priceUrl = this.baseUrl + 'price';
  private orderUrl = this.baseUrl + 'order';
  private bitrixUrl = this.baseUrl + 'bitrix';

  errorMessage: string = '';
 
  getUserList() {
    return this.http
      .get<User[]>(this.usersUrl, this.httpOptions)
      .pipe(retry(1));
  }
  getUser(id: string) {
    return this.http
      .get<User>(this.usersUrl + '/' + id, this.httpOptions)
      .pipe(retry(1));
  }
  getUserPoints(id: string) {
    return this.http
      .get<pointsHistory[]>(this.usersUrl + '/points/' + id, this.httpOptions)
      .pipe(retry(1));
  }
  getUserByPhone(phone: string) {
    return this.http
      .get<User>(this.usersUrl+`?phone=${phone}`, this.httpOptions).pipe(catchError(this.handleError('getUserByPhone')));
  }
  updateUser(id: string, user: User) {
    return this.http
      .patch<User>(this.usersUrl + '/' + id, user, this.httpOptions);
  }
  updateUserPassword(password: string) {
    return this.http
      .patch<User>(this.usersUrl, {password}, this.httpOptions);
  }
  updateUserPasswordAdmin(password: string, phone: string) {
    return this.http
      .patch<User>(this.usersUrl, {password, phoneBody: phone}, this.httpOptions);
  }
  register(user: User) {
    return this.http
      .post(this.usersUrl, { user }, this.httpOptions);
  }

  login(phone: number, password: string) {
    return this.http
      .post(
        this.authUrl,
        { phone, password },
        {
          withCredentials: true,
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      );
      // .pipe(retry(1));
  }

  logout() {
    return this.http
      .post(
        `${this.authUrl}/logout`,
        { yep: 'yep' }, //empty body because POST
        {
          withCredentials: true,
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(catchError(this.handleError('logout')));
  }

  refresh() {
    return this.http
      .get<{ accessToken: string }>(this.authUrl + '/refresh', {
        withCredentials: true,
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      });
      // .pipe(retry(2));
  }

  getOptions(): Observable<Options> {
    return this.http
      .get<Options>(this.optionsUrl)
      .pipe(retry(1), catchError(this.handleError<Options>('getOptions')));
  }
  updateOptions(options: any): Observable<Object> {
    return this.http
      .patch<Options>(this.optionsUrl, options, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError('updateOptions', options)));
  }

  getPrice(): Observable<Price> {
    return this.http
      .get<Price>(this.priceUrl)
      .pipe(retry(1), catchError(this.handleError<Price>('getPrice')));
  }
  updatePrice(price: any): Observable<Object> {
    return this.http
      .patch<Price>(this.priceUrl, price, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError('updatePrice', price)));
  }
  createOrder(order: Order) {
    return this.http
      .post<Order>(this.orderUrl, order, this.httpOptions)
      .pipe(catchError(this.handleError('createOrder', order)));
  }
  updateOrderById(id: string, data: any) {
    return this.http
      .post<Order>(this.orderUrl+'/'+id, data, this.httpOptions)
      .pipe(catchError(this.handleError('updateOrderById', data)));
  }
  getOrderList(parentID: string): Observable<Order[]> {
    if(!parentID){
      parentID = 'blank';
    }
    console.log(this.orderUrl+'/list/'+parentID);
    return this.http
      .get<Order[]>(this.orderUrl+'/list/'+parentID,this.httpOptions)
      .pipe(retry(1), catchError(this.handleError<Order[]>('getOrderList')));
  }
  getOrder(id: string): Observable<Order> {
    return this.http
      .get<Order>(this.orderUrl + '/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError<Order>('getOrder')));
  }
  deleteOrder(id: string): Observable<string> {
    return this.http
      .delete<string>(this.orderUrl + '/'+ id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError<string>('deleteOrder')));
  }
  deleteUser(id: string): Observable<string> {
    return this.http
      .delete<string>(this.usersUrl + '/'+ id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError<string>('deleteUser')));
  }
  changeBitrixWorkID(bitrixWorkID: string): Observable<Object> {
    return this.http.post<Object>(this.bitrixUrl, { bitrixWorkID } ,this.httpOptions).pipe(catchError(this.handleError<Object>('changeBitrixWorkID')));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if(errorMessage){<p class="error-message">{{ errorMessage }}</p>}
    @if(loading){
      <h3>Загрузка</h3>
    } @else{
      <div class="buttons-top">
      @if(!parentCheck){
        @if(isAtLeastManager){
          <div class="button-bottom">
            <button class="button delete-button" (click)="deleteOrder()"> Удалить заказ </button>
          </div>
        } @else{
          @if(['saved', 'pending', 'review'].includes(order.status)){
            <div class="button-bottom">
              <button class="button delete-button" (click)="cancelOrder()"> Отменить заказ </button>
              <span class="green" style="background-color: white; font-size: large;"> {{ cancelMessage }} </span>
            </div>
          }
        }
        @if(order.status === 'saved'){
          <button class="button push-button" (click)="sendOrder()" [disabled]="(crmContactId === 0 && crmCompanyId === 0) && !isAtLeastManager"> Получить счёт </button>
          @if ((crmContactId === 0 && crmCompanyId === 0) && !isAtLeastManager){
            <p style="display:inline">Получить счёт временно не возможно(тех. работы)</p>
          }
          <div class="payment">
            <div>
              <label class="label-payment" for="paymentPlan">Планируемая форма оплаты</label>
              <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentMethod">
                @for (opt of PAYMENT_METHODS; track $index) {
                  <option value="{{opt.key}}"> {{ opt.label }} </option>
                }
                <option selected disabled value="none">----</option>
              </select>
            </div>
          </div>
          <div class="payment payment-date">
            <div>
              <label class="label-payment" for="paymentDate">Планируемая дата оплаты</label>
              <input class='input-payment' [ngModel]="paymentDate | date:'yyyy-MM-dd'"
              (ngModelChange)="paymentDate = $event"
              type="date" id="paymentDate" class="input-date" value="{{paymentDate}}" min="2024-17-07" max="2025-12-31" />
            </div>
          </div>
        }
        @if(isAtLeastManager){
          <button class="button export-button" (click)="exportToCSV()"> Скачать файл</button>
          <input class='delimiter' type="checkbox" id="delimiter" name="delimiter" [(ngModel)]="delimiter"/>
          <label class='delimiter'  for="delimiter">Сменить разделитель (excel)</label>
        }
        <div class="button-bottom">
          <button class="button export-button" (click)="copyOrder()"> Открыть и изменить заказ </button>
        </div>
        @if(isAtLeastProvision){
          @if(order.status === 'review'){
            <button class="button export-button" (click)="moveStage(1)">Отправить счёт</button>
            <input type="text" placeholder="Номер заказа" [(ngModel)]="customId"/>
          } @else if (order.status === 'pending') {
            <button class="button export-button" (click)="moveStage(2)">Оплата получена</button>
            <div class="payment">
              <div>
                <label class="label-payment" for="moneyLeft">Внесенная сумма</label>
                <input id="amountPaid" type="text" placeholder="Внесенная сумма" [(ngModel)]="amountPaid" onclick="this.select()"/>
              </div>
              <div>
                <label class="label-payment" for="supplierPrice">Сумма закупа</label>
                <input id="supplierPrice" type="text" placeholder="Сумма закупа" [(ngModel)]="supplierPrice" onclick="this.select()"/>
              </div>
            </div>
            <div class="payment payment-date">
              <div>
                <label class="label-payment" for="paymentDate">Дата оплаты</label>
                <input class='input-payment' [ngModel]="paymentDate | date:'yyyy-MM-dd'"
                (ngModelChange)="paymentDate = $event"
                type="date" id="paymentDate" class="input-date" value="{{paymentDate}}" min="2024-17-07" max="2025-12-31" />
              </div>
            </div>
            <div class="payment">
              <div>
                <label class="label-payment" for="paymentPlan">Форма оплаты</label>
                <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentMethod">
                  @for (opt of PAYMENT_METHODS; track $index) {
                    <option value="{{opt.key}}"> {{ opt.label }} </option>
                  }
                  <option selected disabled value="none">----</option>
                </select>
              </div>
            </div>
          } @else if (order.status === 'pending-manufacturer') {
            @if (!hasSupplierPrice){
              <div class="payment">
                <label class="label-payment" for="supplierPrice">Сумма закупа</label>
                <input id="supplierPrice" type="text" placeholder="Сумма закупа" [(ngModel)]="supplierPrice" onclick="this.select()"/>
              </div>
            }
            <div class="payment payment-date">
              <div>
                <label class="label-payment" for="paymentSupplierDate">Дата оплаты поставщику</label>
                <input class='input-payment' [ngModel]="paymentSupplierDate | date:'yyyy-MM-dd'"
                (ngModelChange)="paymentSupplierDate = $event"
                type="date" id="paymentSupplierDate" class="input-date" value="{{paymentSupplierDate}}" min="2024-17-07" max="2025-12-31" />
              </div>
            </div>
            <div class="payment">
              <div>
                <label class="label-payment" for="supplier">Поставщик</label>
                <select class='text-design payment-select' id="supplier" [(ngModel)]="supplier">
                  <option value="Мебельоптсервис">Мебельоптсервис</option>
                  <option value="Современныефасады">Современныефасады</option>
                  <option value="Очень хорошие двери">Очень хорошие двери</option>
                  <option selected disabled value="none">----</option>
                </select>
              </div>
            </div>
            <button class="button export-button" (click)="moveStage(3)">Оплата поставщику отправлена</button>
          } @else if (order.status === 'manufacturing') {
            <div class="payment payment-date">
              <div>
                <label class="label-payment" for="ataWarehouse">Дата прихода на склад</label>
                <input class='input-payment' [ngModel]="ataWarehouse | date:'yyyy-MM-dd'"
                (ngModelChange)="ataWarehouse = $event"
                type="date" id="ataWarehouse" class="input-date" value="{{ataWarehouse}}" min="2024-17-07" max="2025-12-31" />
              </div>
            </div>
            <div class="payment">
              <label class="label-payment" for="attachments">Вложения</label>
              <input id="attachments" type="text" placeholder="Вложения" [(ngModel)]="attachments" onclick="this.select()"/>
            </div>
            <!-- <div class="payment">
              <label class="label-payment" for="full">Полный заказ?</label>
              <input id="attachments" type="checkbox" [(ngModel)]="full"/>
            </div> -->
            <button class="button export-button" (click)="moveStage(4)">Оприходовать на склад</button>
          } @else if (order.status === 'warehouse') {
            <div class="payment payment-date">
              <div>
                <label class="label-payment" for="pickedUpAt">Дата отгрузки</label>
                <input class='input-payment' [ngModel]="pickedUpAt | date:'yyyy-MM-dd'"
                (ngModelChange)="pickedUpAt = $event"
                type="date" id="pickedUpAt" class="input-date" value="{{pickedUpAt}}" min="2024-17-07" max="2025-12-31" />
              </div>
            </div>
            <!-- <div class="payment">
              <label class="label-payment" for="receiver">Кто отгрузил</label>
              <input id="receiver" type="text" placeholder="Кто отгрузил" [(ngModel)]="receiver" onclick="this.select()"/>
            </div> -->
            <button class="button export-button" (click)="moveStage(5)">Отгрузить</button>
          }
        }
      } @else {
        <div class="button-bottom">
          <button class="button export-button" (click)="copyOrder()"> Открыть и изменить заказ </button>
        </div>
      }

    </div>
      <div class="border-radius">
        <table class="main-info">
          <thead>
            <tr>
              <th>Создан</th>
              <th>План-дата поступления на склад</th>
              <th>Факт-дата поступления на склад</th>
              <th>Номер</th>
              <th>Статус</th>
              <th>Розничная сумма</th>
              @if(personCheck){
                <th>Оптовая сумма</th>
                <th>Бонусов использовано</th>
              }
              <th>S<sub>общ</sub></th>
              <th>Ответственный</th>
              <th>Счёт на оплату</th>
              @if (['pending-manufacturer', 'manufacturing', 'warehouse', 'shipped'].includes(order.status)) {
                <th>Метод оплаты</th>
              }@else{
                <th>Планируемый метод оплаты</th>
              }
              @if(isNotRetail){
                <th>ТОРГ-12</th>
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ order.created | dateColhoz }}</td>
              <td>{{ order.etaWarehouse }}
                  @if(!order.etaWarehouse)
                    { ---- }
              </td>
              <td>
              @if(!order.ataWarehouse) {
                   ----
                } @else{
                  {{ order.ataWarehouse}}
                }

              </td>
              <td>
                <a href="/order/{{ order._id }}" class="number-text">
                  @if(!order.customId)
                    { Номер }
                  @else {
                    {{ order.customId }}
                  }
                </a>
                <p class="type">
                  @if(order.kind){
                    {{ TYPE_TO_RUS[order.kind] }}
                  }
                </p>
              </td>
              <td [ngClass]="{'red': order.status === 'pending', 'yellow': order.status === 'manufacturing' || order.status === 'pending-manufacturer', 'purple': isAtLeastManager && order.status === 'pending-manufacturer', 'green': order.status ==='warehouse', 'blue': order.status ==='saved'}">
                {{ MAP_STATUS[order.status] }}
              </td>
              <td> <span style="text-wrap: nowrap;">{{ order.totalPrice | number }} </span></td>
              @if(personCheck){
                  <td>
                    <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup | number }} </span>
                  </td>
                  <td>
                    <span style="text-wrap: nowrap;">{{ order.pointsUsed | number }} </span>
                  </td>
              }
              <td><span style="text-wrap: nowrap;">{{order.totalArea}} м<sup>2</sup></span></td>
              <td>
                <ng-container>
                    <p style="text-decoration: underline;"
                       matTooltip="89000804530"
                       matTooltipPosition="above">
                       Третьяков Кирилл
                    </p>
                </ng-container>
              </td>
              <td>
                @if(order.invoice){
                  <a href="{{ order.invoice }}" class="number-text" target="_blank">
                    Счёт
                  </a>
                } @else {
                  Счёт не сформирован
                }
              </td>
              <td>
                @if(order.paymentMethod){
                  {{ PAYMENT_METHODS_IDK[order.paymentMethod] }}
                }@else {
                  Не указан метод оплаты
                }
              </td>
              @if (isNotRetail){
                <td>
                  @if(order.torg12){
                    {{ order.torg12 }}
                  }@else{
                    ----
                  }
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
      @if(isAtLeastManager){
        <div class="border-radius">
          <table class="main-info">
            <thead>
              <tr>
                @if (['pending-manufacturer', 'manufacturing', 'warehouse', 'shipped'].includes(order.status)) {
                  <th>Дата оплаты</th>
                  <th>Метод оплаты</th>
                }@else{
                  <th>Планируемая дата оплаты</th>
                  <th>Планируемый метод оплаты</th>
                }
                <th>Дата оплаты поставщику</th>
                <th>Дата отгрузки поставщику</th>
                <th>Лицо</th>
                <th>ФИО</th>
                @if (isAtLeastProvision) {
                  <th>Сумма закупа</th>
                  @if(order.moneyLeft !== 0){
                    <th>Внесенная сумма</th>
                  }
                <th>Поставщик</th>
                <th>Вложения</th>
                <th>Заказ</th>
                <th>Кто отгрузил</th>
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {{ order.paymentDate }} </td>
                <td>
                  @if(order.paymentMethod){
                    {{ PAYMENT_METHODS_IDK[order.paymentMethod] }}
                  }@else {
                    Не указан метод оплаты
                  }
                </td>
                <td>
                  @if(order.paymentSupplierDate){
                    {{ order.paymentSupplierDate }}
                  }@else {
                    ----
                  }
                </td>
                <td>
                  @if(order.pickedUpAt){
                    {{ order.pickedUpAt }}
                  }@else {
                    ----
                  }
                </td>
                <td>{{ order.person }}</td>
                <td [ngClass]="{'green-bg': order.createdResponsible === name}">
                  {{ order.name }}<ng-container *ngIf="order.createdResponsible" >
                    <span
                       matTooltip="{{ order.createdResponsible }}"
                       matTooltipPosition="above">*
                    </span>
                  </ng-container>
                </td>
                @if (isAtLeastProvision) {
                  <td>
                    @if(order.supplierPrice){
                      {{ order.supplierPrice }}
                    }@else {
                      ----
                    }
                  </td>
                  @if(order.moneyLeft !== 0){
                    <td>
                      @if (order.totalPriceMarkup && order.moneyLeft){
                        {{ order.totalPriceMarkup - order.moneyLeft }}
                      }
                    </td>
                  }
                  <td>
                    @if(order.supplier){
                      {{ order.supplier }}
                    }@else {
                      ----
                    }
                  </td>
                  <td>
                    @if(order.attachments){
                      {{ order.attachments }}
                    }@else {
                      ----
                    }
                  </td>
                  <td>
                    @if(order.attachments === undefined || order.attachments === null){
                      ----
                    }@else {
                      @if(order.full){
                        Полный
                      }@else {
                        Неполный
                      }
                    }
                  </td>
                  <td>
                    @if(order.receiver){
                      {{ order.receiver }}
                    }@else {
                      ----
                    }
                  </td>
                }
              </tr>
            </tbody>
          </table>
        </div>
      }
      @if(kind === 'facade'){
          <div class="border-radius">
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th (click)="sort('typeF')">
                  Тип
                  <span style="text-wrap: nowrap;">
                    фасада <i class="fa" [ngClass]="{'fa-sort': sortField !== 'typeF', 'fa-sort-up': sortField === 'typeF' && sortOrder === 'asc', 'fa-sort-down': sortField === 'typeF' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('thickness')">
                  <span style="text-wrap: nowrap;">
                    Толщина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'thickness', 'fa-sort-up': sortField === 'thickness' && sortOrder === 'asc', 'fa-sort-down': sortField === 'thickness' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('collect')">
                  <span style="text-wrap: nowrap;">
                    Коллекция <i class="fa" [ngClass]="{'fa-sort': sortField !== 'collect', 'fa-sort-up': sortField === 'collect' && sortOrder === 'asc', 'fa-sort-down': sortField === 'collect' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <!-- <th>Фрезеровка</th>-->
                @if(millingCheck){
                  <th (click)="sort('milling')">
                    <span style="text-wrap: nowrap;">
                      Фрезеровка <i class="fa" [ngClass]="{'fa-sort': sortField !== 'milling', 'fa-sort-up': sortField === 'milling' && sortOrder === 'asc', 'fa-sort-down': sortField === 'milling' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                @if(frezaCheck){
                  <th (click)="sort('freza')">
                    Обкатная
                    <span style="text-wrap: nowrap;">
                      фреза <i class="fa" [ngClass]="{'fa-sort': sortField !== 'freza', 'fa-sort-up': sortField === 'freza' && sortOrder === 'asc', 'fa-sort-down': sortField === 'freza' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                <th (click)="sort('decor')">
                  <span style="text-wrap: nowrap;">
                    Декор <i class="fa" [ngClass]="{'fa-sort': sortField !== 'decor', 'fa-sort-up': sortField === 'decor' && sortOrder === 'asc', 'fa-sort-down': sortField === 'decor' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(kromkaCheck){
                  <th (click)="sort('kromka')">
                  <span style="text-wrap: nowrap;">
                    Кромка <i class="fa" [ngClass]="{'fa-sort': sortField !== 'kromka', 'fa-sort-up': sortField === 'kromka' && sortOrder === 'asc', 'fa-sort-down': sortField === 'kromka' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(kromkaDesignCheck){
                  <th (click)="sort('kromkaDesign')">
                  Дизайн
                  <span style="text-wrap: nowrap;">
                    кромки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'kromkaDesign', 'fa-sort-up': sortField === 'kromkaDesign' && sortOrder === 'asc', 'fa-sort-down': sortField === 'kromkaDesign' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(textureCheck){
                  <th (click)="sort('texture')">
                  <span style="text-wrap: nowrap;">
                    Текстура <i class="fa" [ngClass]="{'fa-sort': sortField !== 'texture', 'fa-sort-up': sortField === 'texture' && sortOrder === 'asc', 'fa-sort-down': sortField === 'texture' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(starCheck){
                  <th (click)="sort('star')">
                  <span style="text-wrap: nowrap;">
                    Старение <i class="fa" [ngClass]="{'fa-sort': sortField !== 'star', 'fa-sort-up': sortField === 'star' && sortOrder === 'asc', 'fa-sort-down': sortField === 'star' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(polishCheck){
                  <th (click)="sort('polish')">
                  <span style="text-wrap: nowrap;">
                    Лак <i class="fa" [ngClass]="{'fa-sort': sortField !== 'polish', 'fa-sort-up': sortField === 'polish' && sortOrder === 'asc', 'fa-sort-down': sortField === 'polish' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                <th (click)="sort('ispol')">
                  Вид <span style="text-wrap: nowrap;">
                    фасада <i class="fa" [ngClass]="{'fa-sort': sortField !== 'ispol', 'fa-sort-up': sortField === 'ispol' && sortOrder === 'asc', 'fa-sort-down': sortField === 'ispol' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(hingeCheck){
                  <th (click)="sort('hinge')">
                  <span style="text-wrap: nowrap;">
                    Присадки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'hinge', 'fa-sort-up': sortField === 'hinge' && sortOrder === 'asc', 'fa-sort-down': sortField === 'hinge' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                <th (click)="sort('height')">
                  <span style="text-wrap: nowrap;">
                    Высота <i class="fa" [ngClass]="{'fa-sort': sortField !== 'height', 'fa-sort-up': sortField === 'height' && sortOrder === 'asc', 'fa-sort-down': sortField === 'height' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('width')">
                  <span style="text-wrap: nowrap;">
                    Ширина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'width', 'fa-sort-up': sortField === 'width' && sortOrder === 'asc', 'fa-sort-down': sortField === 'width' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('amount')">
                  <span style="text-wrap: nowrap;">
                    Кол-во <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amount', 'fa-sort-up': sortField === 'amount' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amount' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('totalArea')">
                  <span style="text-wrap: nowrap;">
                    S<sub>общ</sub><i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalArea', 'fa-sort-up': sortField === 'totalArea' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalArea' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('price')">
                  Розничная <span style="text-wrap: nowrap;">
                    цена <i class="fa" [ngClass]="{'fa-sort': sortField !== 'price', 'fa-sort-up': sortField === 'price' && sortOrder === 'asc', 'fa-sort-down': sortField === 'price' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('priceCalc')">
                  Розничная <span style="text-wrap: nowrap;">
                    сумма <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceCalc', 'fa-sort-up': sortField === 'priceCalc' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceCalc' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(isNotRetail) {
                  <th (click)="sort('priceMarkup')">
                    Оптовая сумма
                    <span style="text-wrap: nowrap;">
                    <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceMarkup', 'fa-sort-up': sortField === 'priceMarkup' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceMarkup' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                <!-- <th (click)="sort('area')">
                  <span style="text-wrap: nowrap;">
                    S<sub>детали</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'area', 'fa-sort-up': sortField === 'area' && sortOrder === 'asc', 'fa-sort-down': sortField === 'area' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('totalArea')">
                  <span style="text-wrap: nowrap;">
                    S<sub>общая</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalArea', 'fa-sort-up': sortField === 'totalArea' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalArea' && sortOrder === 'desc'}"></i>
                  </span>
                </th> -->
              </tr>
            </thead>
            <tbody>
              @for (position of order.order; track $index) {
              <tr>
                <td>
                  <span style="text-wrap: nowrap;">
                    <button class="empty-button" (click)="moveSuborder($index, 'up')" [disabled]="$index === 0">
                      <i class="fa fa-sort-up"></i>
                    </button>
                    {{ $index+1}}
                    <button class="empty-button" (click)="moveSuborder($index, 'down')" [disabled]="$index === length- 1">
                      <i class="fa fa-sort-down"></i>
                    </button>
                  </span>
                </td>
                <td> {{ position.typeF }} </td>
                <td> {{ position.thickness }} </td>
                <td> {{ position.collect }} </td>
                @if(millingCheck){
                  <td>
                  @if(position.milling){
                    {{ position.milling }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(frezaCheck){
                  <td>
                  @if(position.freza){
                    {{ position.freza }}
                  }
                  @else { ---- }
                  </td>
                }
                <td> {{ position.decor }} </td>
                @if(kromkaCheck){
                  <td>
                  @if(position.kromka){
                    {{ MAP_KROMKA[position.kromka] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(kromkaDesignCheck){
                  <td>
                  @if(position.kromkaDesign){
                    {{ position.kromkaDesign }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(textureCheck){
                  <td>
                  @if(position.texture){
                    {{ MAP_TEXTURE[position.texture] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(starCheck){
                  <td>
                  @if(position.star){
                    {{ MAP_STAR[position.star] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(polishCheck){
                  <td>
                  @if(position.polish){
                    {{ position.polish }}
                  }
                  @else { ---- }
                  </td>
                }
                <td>
                  @if(position.ispol){
                    {{ position.ispol }}
                  }
                  @else { ---- }
                </td>
                @if(hingeCheck){
                  <td>
                  @if(position.hinge){
                    {{ position.hinge }}
                  }
                  @else { ---- }
                  </td>
                }
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.height | number }}
                  }
                </td>
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.width | number }}
                  }
                </td>
                <td>
                  {{ position.amount  }}
                  @if(position.typeF === 'LUX кромка'){
                     м.п.
                  }
                  </td>
                <td> <span style="text-wrap: nowrap;">{{ position.totalArea }} м<sup>2</sup></span> </td>
                <td> <span style="text-wrap: nowrap;">{{ position.price | number }} </span></td>
                <td> <span style="text-wrap: nowrap;"> {{ position.priceCalc | number }} </span> </td>
                @if(isNotRetail){
                  <td> <span style="text-wrap: nowrap;"> {{ position.priceMarkup  | number}} </span></td>
                }
                <!-- <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.area | number }}м<sup>2</sup>
                  }
                </td>
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.totalArea | number }}м<sup>2</sup>
                  }
                </td> -->
              </tr>
              }
              @for (position of subOrderUnnamed; track $index) {
                <tr>
                  <td>
                    ----
                  </td>
                  <td> {{ position.name }} </td>
                  <td> ---- </td>
                  <td> ---- </td>
                  @if(millingCheck){
                    <td>
                      ----
                    </td>
                  }
                  @if(frezaCheck){
                    <td>
                      ----
                    </td>
                  }
                  <td> ---- </td>
                  @if(kromkaCheck){
                    <td>
                      ----
                    </td>
                  }
                  @if(kromkaDesignCheck){
                    <td>
                      ----
                    </td>
                  }
                  @if(textureCheck){
                    <td>
                      ----
                    </td>
                  }
                  @if(starCheck){
                    <td>
                      ----
                    </td>
                  }
                  @if(polishCheck){
                    <td>
                      ----
                    </td>
                  }
                  <td>
                    ----
                  </td>
                  @if(hingeCheck){
                    <td>
                      ----
                    </td>
                  }
                  <td>
                    @if(position.height){
                      {{ position.height | number }}
                    }@else {
                      ----
                    }
                  </td>
                  <td>
                    @if(position.width){
                      {{ position.width | number }}
                    }@else {
                      ----
                    }
                  </td>
                  <td>
                    {{ position.amount  }}
                  </td>
                  <td></td>
                  <td> <span style="text-wrap: nowrap;">{{ position.price | number }} </span></td>
                  <td> <span style="text-wrap: nowrap;"> {{ position.priceCalc | number }} </span> </td>
                  @if(isNotRetail){
                    <td> <span style="text-wrap: nowrap;"> {{ position.priceMarkup  | number}} </span></td>
                  }
                </tr>
                }

              <tr>
                <td>Итого </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                @if(millingCheck){
                  <td></td>
                }
                @if(frezaCheck){
                  <td></td>
                }
                @if(kromkaCheck){
                  <td></td>
                }
                @if(kromkaDesignCheck){
                  <td></td>
                }
                @if(textureCheck){
                  <td></td>
                }
                @if(starCheck){
                  <td></td>
                }
                @if(polishCheck){
                  <td></td>
                }
                @if(hingeCheck){
                  <td>{{hingeAmount}}</td>
                }
                <td></td>
                <td></td>
                <td> {{ totalAmount }} </td>
                <td> <span style="text-wrap: nowrap;">{{ order.totalArea }} м<sup>2</sup></span></td>
                <td></td>
                @if(isNotRetail){
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc  | number}}</span></td>
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup  | number}}</span></td>
                }@else {
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc | number }} </span></td>
                }
                <!-- <td></td> -->
                <!-- <td>{{ order.totalArea | number }}м<sup>2</sup></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        }@else if(kind === 'lamella'){
          <div class="border-radius-lamella">
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th (click)="sort('typeL')">
                  Тип
                  <span style="text-wrap: nowrap;">
                    рейки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'typeL', 'fa-sort-up': sortField === 'typeL' && sortOrder === 'asc', 'fa-sort-down': sortField === 'typeL' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <!-- <th (click)="sort('standart')">
                  <span style="text-wrap: nowrap;">
                    Длина реек<i class="fa" [ngClass]="{'fa-sort': sortField !== 'standart', 'fa-sort-up': sortField === 'standart' && sortOrder === 'asc', 'fa-sort-down': sortField === 'standart' && sortOrder === 'desc'}"></i>
                  </span>
                </th> -->
                <th (click)="sort('plug')">
                  <span style="text-wrap: nowrap;">
                    Заглушки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'plug', 'fa-sort-up': sortField === 'plug' && sortOrder === 'asc', 'fa-sort-down': sortField === 'plug' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('decor')">
                  <span style="text-wrap: nowrap;">
                    Декор <i class="fa" [ngClass]="{'fa-sort': sortField !== 'decor', 'fa-sort-up': sortField === 'decor' && sortOrder === 'asc', 'fa-sort-down': sortField === 'decor' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('height')">
                  <span style="text-wrap: nowrap;">
                    Высота <i class="fa" [ngClass]="{'fa-sort': sortField !== 'height', 'fa-sort-up': sortField === 'height' && sortOrder === 'asc', 'fa-sort-down': sortField === 'height' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('width')">
                  <span style="text-wrap: nowrap;">
                    Ширина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'width', 'fa-sort-up': sortField === 'width' && sortOrder === 'asc', 'fa-sort-down': sortField === 'width' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('length')">
                  <span style="text-wrap: nowrap;">
                    Длина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'length', 'fa-sort-up': sortField === 'length' && sortOrder === 'asc', 'fa-sort-down': sortField === 'length' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('amount')">
                  <span style="text-wrap: nowrap;">
                    Кол-во <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amount', 'fa-sort-up': sortField === 'amount' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amount' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('price')">
                  Розничная <span style="text-wrap: nowrap;">
                    цена <i class="fa" [ngClass]="{'fa-sort': sortField !== 'price', 'fa-sort-up': sortField === 'price' && sortOrder === 'asc', 'fa-sort-down': sortField === 'price' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('priceCalc')">
                  Розничная <span style="text-wrap: nowrap;">
                    сумма <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceCalc', 'fa-sort-up': sortField === 'priceCalc' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceCalc' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(isNotRetail) {
                  <th (click)="sort('priceMarkup')">
                    Оптовая сумма
                    <span style="text-wrap: nowrap;">
                      <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceMarkup', 'fa-sort-up': sortField === 'priceMarkup' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceMarkup' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (position of order.orderLamella; track $index) {
              <tr>
                <td>
                  <span style="text-wrap: nowrap;">
                    <button class="empty-button" (click)="moveSuborder($index, 'up')" [disabled]="$index === 0">
                      <i class="fa fa-sort-up"></i>
                    </button>
                    {{ $index+1}}
                    <button class="empty-button" (click)="moveSuborder($index, 'down')" [disabled]="$index === length- 1">
                      <i class="fa fa-sort-down"></i>
                    </button>
                  </span>
                </td>
                <td> {{ MAP_TYPE[position.typeL] }} </td>
                <!-- <td> {{  MAP_STANDART[position.standart] }} </td> -->
                <td> {{ MAP_PLUG[position.plug] }} </td>
                <td> {{ position.decor }} </td>
                <td> {{ position.height | number }} </td>
                <td> {{ position.width | number }} </td>
                <td> {{ position.length | number }} </td>
                <td> {{ position.amount  }} </td>
                <td> <span style="text-wrap: nowrap;">{{ position.price | number }} </span></td>
                <td> <span style="text-wrap: nowrap;"> {{ position.priceCalc | number }} </span> </td>
                @if(isNotRetail){
                  <td> <span style="text-wrap: nowrap;"> {{ position.priceMarkup  | number}} </span></td>
                }
              </tr>
              }
              <tr>
                <td>Итого </td>
                <td></td>
                <!-- <td></td> -->
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td> {{ totalAmount  }} </td>
                <td></td>
                @if(isNotRetail){
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc  | number}}</span></td>
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup  | number}}</span></td>
                }@else {
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc | number }} </span></td>
                }
              </tr>
            </tbody>
          </table>
        </div>
        }
      <div class="comments">
        <span class="comments">Комментарий для менеджера - {{ !!order.commentsForCompany ? order.commentsForCompany : ''}} </span> <br/>
      </div>
      @if(isNotRetail){
        <div class="comments">
          <span class="comments">Комментарий о вашем клиенте - {{ !!order.commentsForClient ? order.commentsForClient : ''}}</span>
        </div>
      }

    }
  </div>

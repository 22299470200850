import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';
export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  console.log(req);
  let authService = inject(AuthService);
  let apiService = inject(ApiService);
  let cookieService = inject(CookieService);
  if (!authService.isAuthenticated()) {
    return next(req);
  }
  let cookie = cookieService.get('Authorization');
  let decodedToken;
  if(cookie){
    decodedToken = jwtDecode(cookieService.get('Authorization'));
  }
  if(decodedToken && decodedToken.exp && (decodedToken.exp > (Math.ceil(Date.now()/1000)))){
      const authReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          `Bearer ${cookieService.get('Authorization')}`
        ),
      });
      return next(authReq);
  }else{
    return next(req).pipe(
      catchError((error1) => {
        if (error1 instanceof HttpErrorResponse
          && !(req.url.includes('auth/login') || req.url.includes('auth/refresh'))
          && error1.status === 401
          || (req.url.includes('order') && error1.status === 400)
        ) {
          return apiService.refresh().pipe(
            switchMap((refreshResult) => {
              authService.setCookie(refreshResult);
              return next(
                req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    `Bearer ${cookieService.get('Authorization')}`
                  ),
                })
              );
            }),
            catchError((error) => {
              console.log(error);
              console.log(error1);
              console.log(req);
              alert('Сессия закончилась, пожалуйста войдите снова');
              authService.logout();
              return throwError(() => error);
            })
          );
        }
        return throwError(() => error1);
      }))
  }


  // if (!authService.isAuthenticated()) {
  //   return next(req);
  // }
  // // if (authService.getCookie()) {
  // if (!localStorage.getItem('getCookie') && authService.getCookie()) {
  //   // console.log('pizdec');
  //   // throw Error();
  //   const authReq = req.clone({
  //     headers: req.headers.set(
  //       'Authorization',
  //       `Bearer ${authService.getCookie()}`
  //     ),
  //   });
  //   return next(authReq);
  // } else {
  //   return next(
  //     req.clone({
  //       headers: req.headers.set(
  //         'wtf', 'this shit'
  //       ),
  //     })
  //   ).pipe(
  //     catchError((error) => {
  //       console.log(req.headers)
  //       if (error instanceof HttpErrorResponse
  //         && !(req.url.includes('auth/login') || req.url.includes('auth/refresh'))
  //         && error.status === 401
  //       ) {
  //         return apiService.refresh().pipe(
  //           switchMap((refreshResult) => {
  //             authService.setCookie(refreshResult);
  //             return next(
  //               req.clone({
  //                 headers: req.headers.set(
  //                   'Authorization',
  //                   `Bearer ${authService.getCookie()}`
  //                 ),
  //               })
  //             );
  //           }),
  //           catchError((error) => {
  //             console.log(error);
  //             alert('Сессия закончилась, пожалуйста войдите снова');
  //             authService.logout();
  //             return throwError(() => error);
  //           })
  //         );
  //       }
  //       return throwError(() => error);
  //     })
  //   );
  // }
};
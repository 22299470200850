import { Component } from '@angular/core';
import { Order, OrderPaymentMethod, OrderWhoIsSupplier, SubOrder, SubOrderUnnamed } from '../interfaces/order';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NumberPipe } from '../pipes/number.pipe';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateColhozPipe } from '../pipes/date-colhoz.pipe';
import { CsvService } from '../services/csv.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [DatePipe, DateColhozPipe, NumberPipe, NgClass, FormsModule, MatTooltipModule, NgIf],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
})
export class OrderComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private csvService: CsvService,
    private roleService: RoleService
  ) {
    authService.getCookie().subscribe((user)=>{
      const role = user.role;
      let person = user.person;
      this.name = user.name;
      if(user.crmCompanyId){
        this.crmCompanyId = user.crmCompanyId;
      }
      if(user.crmContactId){
        this.crmContactId = user.crmContactId;
      }
      if(user.parentID){
        this.parentCheck = true;
      }
      if (person !== 'Розничный покупатель') {
        this.personCheck = true;
        this.isNotRetail = true;
      }
      if (roleService.isAtLeastManager(role)) {
        this.isNotRetail = true;
        this.isAtLeastManager = true;
        this.personCheck = true;
        this.MAP_STATUS['pending-manufacturer'] = 'Ожидает оплаты поставщику';
      }
      if (roleService.isAtLeastProvision(role)) {
        this.isAtLeastProvision = true;
      }
    });

    apiService
      .getOrder(activatedRoute.snapshot.params['id'])
      .subscribe((data: Order) => {
        if ('message' in data) {
          this.errorMessage = JSON.stringify(data.message);
          return;
        }
        if (data) {
          this.loading = false;
          this.errorMessage = '';
          this.order = data;
          this.kind = data.kind;
        } else {
          this.errorMessage = 'Произошла ошибка.';
          return;
        }
        if(data.moneyLeft === undefined || data.moneyLeft === null){
          data.moneyLeft = data.totalPriceMarkup;
        }
        if (data.supplierPrice){
          this.hasSupplierPrice = true;
        }
        if(data.responsiblePhone){
          this.responsiblePhone = data.responsiblePhone;
        }
        if(data.kind == 'facade'){
          if(!data.order || data.order.length == 0){
            if(!data.orderUnnamed || data.orderUnnamed.length == 0){
              this.errorMessage = 'Заказ пуст или произошла ошибка.';
              return;
            }else{
              this.subOrderUnnamed = data.orderUnnamed;
              this.markupCheck = data.totalPrice !== data.totalPriceMarkup;
              return;
            }
          }
          if(data.orderUnnamed){
            this.subOrderUnnamed = data.orderUnnamed;
          }
          this.markupCheck = data.totalPrice !== data.totalPriceMarkup;
          for(const order of data.order){
            if(order.typeF !== 'LUX кромка'){
              this.totalAmount += order.amount;
            }
          }
          this.length = data.order.length;
          this.sort('milling');
          this.checkSubOrderFields();
        }else if (data.kind == 'lamella'){
          if(!data.orderLamella || data.orderLamella.length == 0){
            this.errorMessage = 'Заказ пуст или произошла ошибка.';
            return;
          }
          this.markupCheck = data.totalPrice !== data.totalPriceMarkup;
          for(const order of data.orderLamella){
            this.totalAmount += order.amount;
          }
          this.length = data.orderLamella.length;
          this.sort('decor');
        }
      });
  }

  parentCheck: boolean = false;
  markupCheck: boolean = false;
  isAtLeastManager: boolean = false;

  PAYMENT_METHODS = [
    { key: 'cash', label: 'Наличные, как физическое лицо' },
    { key: 'card', label: 'Картой физического лица' },
    { key: 'transfer', label: 'Перевод на счёт с карты физического лица' },
    { key: 'no_vat', label: 'Расчётный счёт от юридического лица без НДС' },
    { key: 'with_vat', label: 'Расчётный счёт от юридического лица c НДС' }
  ];

  PAYMENT_METHODS_IDK: { [key: string]: string } = {
    'cash': 'Наличные, как физическое лицо',
    'card': 'Картой физического лица',
    'transfer': 'Перевод на счёт с карты физического лица',
    'no_vat': 'Расчётный счёт от юридического лица без НДС',
    'with_vat': 'Расчётный счёт от юридического лица c НДС'
  };

  MAP_STATUS: { [key: string]: string } = {
    'cancelled': 'Отменён',
    'saved': 'Сохранён',
    'review': 'В обработке у менеджера',
    'pending': 'Ожидает оплаты',
    'pending-manufacturer': 'Изготавливается',
    'manufacturing': 'Изготавливается',
    'warehouse': 'На складе',
    'shipped': 'Отгружен',
  };

  MAP_TYPE: { [key: string]: string } = {
    '': '',
    '3side': '3х сторон',
    '4side': '4х сторон',
  };

  MAP_STANDART: { [key: string]: string } = {
    '': '',
    'std': '<= 2790мм',
    'nstd': '>= 2791мм',
  };

  MAP_PLUG: { [key: string]: string } = {
    '': '',
    '0side': 'Нет',
    '1side': '1 ст',
    '2side': '2 ст',
  };

  TYPE_TO_RUS: { [key: string]: string } = {
    '': '',
    'facade': 'Фасады',
    'lamella': 'Рейки',
  };

  MAP_TEXTURE: { [key: string]: string } = {
    '': '',
    'Вертикальная': '↑',
    'Горизонтальная': '→',
  };

  MAP_STAR: { [key: string]: string } = {
    '': '',
    'Без старения': 'Без ст.',
    'Полная патина': 'Полн.',
    'Стандтартная патина': 'Стд.',
  };

  MAP_KROMKA: { [key: string]: string } = {
    '': '',
    'Без кромки': 'Без',
    'Дизайн кромка': 'Дизайн',
    'Кромка в цвет': 'В цвет',
    'С трёх сторон': 'Трёх ст.',
  };

HINGES_AMOUNT: {[key: string]: number} = {
    '2 ГОР 100': 2,
    '2 ГОР 50': 2,
    '2 Л 100': 2,
    '2 Л 50': 2,
    '2 ПР 100': 2,
    '2 ПР 50': 2,
    '3 ГОР 100': 3,
    '3 ГОР 50': 3,
    '3 Л 100': 3,
    '3 Л 50': 3,
    '3 ПР 100': 3,
    '3 ПР 50': 3,
    '4 Л 100': 4,
    '4 Л 50': 4,
    '4 ПР 100': 4,
    '4 ПР 50': 4,
    '5 Л 100': 5,
    '5 Л 50': 5,
    '5 ПР 100': 5,
    '5 ПР 50': 5
  }

  order!: Order;
  subOrderUnnamed!: SubOrderUnnamed[];

  name: string = '';

  loading: boolean = true;
  isAtLeastProvision: boolean = false;
  isNotRetail: boolean = false;
  personCheck: boolean = false;
  responsiblePhone: string = '';
  crmContactId: number = 0;
  crmCompanyId: number = 0;

  delimiter: boolean = false;

  paymentDate: Date = new Date();
  paymentMethod: OrderPaymentMethod = 'none';

  ataWarehouse: Date = new Date();
  attachments: string = '';
  full: boolean = false;

  receiver: string = '';
  pickedUpAt: Date = new Date();

  amountPaid: number = 0;
  supplierPrice: number = 0;

  paymentSupplierDate: Date = new Date();
  supplier: OrderWhoIsSupplier = 'none';

  hasSupplierPrice: boolean = false;

  totalAmount = 0;
  length = 0;
  hingeAmount = 0;

  kind: string = '';

  errorMessage: string = '';
  cancelMessage: string = '';

  millingCheck: boolean = false;
  frezaCheck: boolean = false;
  kromkaCheck: boolean = false;
  kromkaDesignCheck: boolean = false;
  textureCheck: boolean = false;
  starCheck: boolean = false;
  polishCheck: boolean = false;
  hingeCheck: boolean = false;

  customId: string = '';


  // Sorting properties
  sortField: string = 'created'; // Default sort field
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order

  checkSubOrderFields() {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка.';
      return;
    }
    for (const subOrder of this.order.order) {
      if (subOrder.milling !== undefined && subOrder.milling !== '') {
        this.millingCheck = true;
      }
      if (subOrder.freza !== undefined && subOrder.freza !== '') {
        this.frezaCheck = true;
      }
      if (subOrder.kromka !== undefined && subOrder.kromka !== '') {
        this.kromkaCheck = true;
      }
      if (subOrder.kromkaDesign !== undefined && subOrder.kromkaDesign !== '') {
        this.kromkaDesignCheck = true;
      }
      if (subOrder.texture !== undefined && subOrder.texture !== '') {
        this.textureCheck = true;
      }
      if (subOrder.star !== undefined && subOrder.star !== '') {
        this.starCheck = true;
      }
      if (subOrder.polish !== undefined && subOrder.polish !== '') {
        this.polishCheck = true;
      }
      if (subOrder.hinge && subOrder.hinge !== undefined && subOrder.hinge !== '') {
        this.hingeAmount += subOrder.amount * this.HINGES_AMOUNT[subOrder.hinge];
        this.hingeCheck = true;
      }
    }
  }

  moveSuborder(index: number, direction: 'up' | 'down') {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    if (direction === 'up' && index > 0) {
      // Swap the current suborder with the one above it
      [this.order.order[index], this.order.order[index - 1]] = [this.order.order[index - 1], this.order.order[index]];
    } else if (direction === 'down' && index < this.order.order.length - 1) {
      // Swap the current suborder with the one below it
      [this.order.order[index], this.order.order[index + 1]] = [this.order.order[index + 1], this.order.order[index]];
    }
  }

  sort(field: string) {
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new field and default to ascending order
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.sortOrders();
  }

  sortOrders() {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    this.order.order.sort((a, b) => {
      const aValue = a[this.sortField as keyof SubOrder];
      const bValue = b[this.sortField as keyof SubOrder];

      // Handle undefined values for the sort field
      if (aValue === undefined && bValue === undefined) {
        return 0; // Both are undefined, consider equal
      }
      if (aValue === undefined) {
        return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
      }
      if (bValue === undefined) {
        return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
      }

      // Compare the primary sort values based on their types
      let comparison = 0;
       // For other fields, use the existing comparison logic
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        }
      // Return the comparison result based on sort order
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  }

  deleteOrder(){
    if(confirm("Точно удалить данный заказ?")){
      if(this.order._id){
        this.apiService.deleteOrder(this.order._id).subscribe((data)=>{
          if(data && '_id'){
            this.authService.setCookieDeletedOrder(data);
          }
          this.router.navigate(['/list']);
        });
      } else {
        this.errorMessage = "Не найден номер заказа.";
      }
    }
  }
  cancelOrder(){
    if(confirm("Отправить уведомление об отмене заказа?")){
      if(this.order._id){
        this.apiService.deleteOrder(this.order._id).subscribe((data)=>{
          //@ts-ignore
          if(data && data.message){
            //@ts-ignore
            this.cancelMessage = data.message;
          }else{
            this.authService.setCookieDeletedOrder({_id: 'Без номера'});
            this.router.navigate(['/list']);
          }
        });
      } else {
        this.errorMessage = "Не найден номер заказа.";
      }
    }
  }
  sendOrder(){
    this.errorMessage = '';
    if(!this.paymentMethod){
      this.errorMessage = 'Не указана форма оплаты';
      return;
    }
    if(!this.paymentDate){
      this.errorMessage = 'Не указана планируемая дата оплаты';
      return;
    }
    this.order.paymentMethod = this.paymentMethod,
    this.order.paymentDate = this.paymentDate;

    this.apiService.createOrder(this.order).subscribe((data)=>{
      location.reload();
    })
  }
  exportToCSV(): void {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    const csvContent = this.csvService.jsonToCSV(this.order.order,
      [ this.order.created,
        this.order.paymentDate,
        this.order.etaWarehouse,
        this.order.ataWarehouse,
        this.order.customId,
        this.order.status,
        this.order.totalPrice,
        this.order.totalPriceMarkup,
        this.order.totalPriceDiscount,
        this.order.person,
        this.order.name,
        this.order.totalArea,
        this.order.responsible
      ],
      this.delimiter ? ';' : ','
    );
    this.csvService.downloadCSV(csvContent, 'data.csv');
  }

  copyOrder(){
    if(this.kind === 'facade'){
      localStorage.setItem('order', JSON.stringify(this.order));
      this.router.navigate(['/create']);
    }else if(this.kind === 'lamella'){
      localStorage.setItem('order', JSON.stringify(this.order));
      this.router.navigate(['/create-lamella']);
    }
  }

  moveStage(stage: number): void{
    this.errorMessage = '';
    if (!this.order._id){
      this.errorMessage = 'Нету id заказа';
      return;
    }
    //1 review -> pending, 2 pending -> pending-manufacturer, 3 pending-manufacturer -> manufacturing, 4 manufacturing -> warehouse, 5 warehouse -> shipped
    switch(stage){
    case 1:
      if(!this.customId){
        this.errorMessage = 'Не указан номер заказа';
        return;
      }
      this.apiService.updateOrderById(this.order._id, {stage: 1, customId: this.customId}).subscribe((data)=>{
        if(data && data.errorMessage){
          console.error(data.errorMessage);
        }
        window.location.reload();
      });
      break;
    case 2:
      if(!this.amountPaid){
        this.errorMessage = 'Не указана внесенная сумма';
        return;
      }
      if(!this.isValidNumber(this.amountPaid)){
        this.errorMessage = 'Неправильно введена внесенная сумма (в числе не должно быть запятых и пробелов, дробная часть вводится через точку (999.99))';
        return;
      }
      if(this.order.moneyLeft && this.amountPaid >= this.order.moneyLeft){
        if(!this.supplierPrice){
          this.errorMessage = 'Не указана сумма закупа';
          return;
        }
        if(!this.isValidNumber(this.supplierPrice)){
          this.errorMessage = 'Неправильно введена сумма закупа (в числе не должно быть запятых и пробелов, дробная часть вводится через точку (999.99))';
          return;
        }
        if(!this.paymentDate){
          this.errorMessage = 'Не указана дата оплаты';
          return;
        }
        if(!this.paymentMethod || this.paymentMethod === 'none'){
          this.errorMessage = 'Не указан метод оплаты';
          return;
        }
      }

      this.apiService.updateOrderById(this.order._id, {stage: 2, paymentDate: this.paymentDate, paymentMethod: this.paymentMethod, amountPaid: this.amountPaid, supplierPrice: this.supplierPrice }).subscribe((data)=>{
        if(data && data.errorMessage){
          console.error(data.errorMessage);
        }
        window.location.reload();
      });
      break;
    case 3:
      if(!this.hasSupplierPrice && !this.supplierPrice){
        this.errorMessage = 'Не указана сумма закупа';
        return;
      }
      if(!this.supplier || this.supplier === 'none'){
        this.errorMessage = 'Не указан поставщик';
        return;
      }
      if(!this.paymentSupplierDate){
        this.errorMessage = 'Не указана дата оплаты поставщику';
        return;
      }
      if(!this.hasSupplierPrice && !this.isValidNumber(this.supplierPrice)){
        this.errorMessage = 'Неправильно введена сумма закупа (в числе не должно быть запятых и пробелов, дробная часть вводится через точку (999.99))'
        return;
      }

      this.apiService.updateOrderById(this.order._id, {stage: 3, supplierPrice: this.supplierPrice, supplier: this.supplier, paymentSupplierDate: this.paymentSupplierDate }).subscribe((data)=>{
        if(data && data.errorMessage){
          console.error(data.errorMessage);
        }
        window.location.reload();
      });
      break;
    case 4:
      if(!this.attachments || this.attachments === ''){
        this.errorMessage = 'Не указаны вложения';
        return;
      }
      if(!this.ataWarehouse){
        this.errorMessage = 'Не указана дата прихода на склад';
        return;
      }

      this.apiService.updateOrderById(this.order._id, {stage: 4, attachments: this.attachments, ataWarehouse: this.ataWarehouse }).subscribe((data)=>{
        if(data && data.errorMessage){
          console.error(data.errorMessage);
        }
        window.location.reload();
      });
      break;
    case 5:
      // if(!this.receiver || this.receiver === ''){
      //   this.errorMessage = 'Не указано кому отгружено';
      //   return;
      // }
      if(!this.pickedUpAt){
        this.errorMessage = 'Не указана дата отгрузки';
        return;
      }

      this.apiService.updateOrderById(this.order._id, {stage: 5, pickedUpAt: this.pickedUpAt }).subscribe((data)=>{
        if(data && data.errorMessage){
          console.error(data.errorMessage);
        }
        window.location.reload();
      });
      break;
    }
  }
  isValidNumber (input: any): boolean {
    return !isNaN(input) && input !== null && input !== '';
  }
}

  <div [ngClass]="{
    'page-create': router.url==='/create',
    'page-order': router.url.includes('/order/'),
    'page-list': router.url.includes('/list'),
    'page-clients': router.url.includes('/clients'),
    'page-client': router.url.includes('/client/'),
    }">
  <mat-sidenav-container class="sidenav-container">
    <!-- SIDENAV -->

    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="((handset.isHandset$ | async) || isMobile) ? 'dialog' : 'navigation'"
      [mode]="((handset.isHandset$ | async) || isMobile) ? 'over' : 'side'"
      [opened]="false"
    >
      <mat-toolbar class="menu-text">Меню</mat-toolbar>
      <mat-nav-list class="nav-list">
        <a mat-list-item routerLink="/create" (click)="drawer.close()"
          ><span class='list-item'>Сделать заказ</span></a
        >
        <!-- <mat-nav-list class="nav-list">
          <button mat-list-item [matMenuTriggerFor]="orderMenu" (click)="drawer.close()">
            <span class='list-item'>Сделать заказ</span>
          </button>
          <mat-menu #orderMenu="matMenu">
            <button mat-menu-item routerLink="/make-facade" (click)="drawer.close()">
              <span class='list-item'>Сделать фасад</span>
            </button>
            <button mat-menu-item routerLink="/make-lamella" (click)="drawer.close()">
              <span class='list-item'>Сделать ламеллу</span>
            </button>
          </mat-menu> -->
        <a class='list-item' mat-list-item routerLink="/list" (click)="drawer.close()">
          <span class='list-item'>Журнал заказов</span>
        </a>
        <a class='list-item' mat-list-item routerLink="/support" (click)="drawer.close()">
          <span class='list-item'>Наши контакты</span>
        </a>
        @if(isAuthorizedManager){
        <a class='list-item'  mat-list-item routerLink="/clients" (click)="drawer.close()">
          <span class='list-item'>Клиенты</span>
        </a>
      } @if (isAuthorizedOwner) {<a
          class='list-item'
          mat-list-item
          routerLink="/cabinet"
          (click)="drawer.close()">
          <span class='list-item'>Кабинет</span>
        </a>
        }
        <a class='list-item' mat-list-item routerLink="/lk" (click)="drawer.close()">
          <span class='list-item'>Личный кабинет</span>
        </a>
         @if (isAuthenticated) {<a
          class='list-item'
          mat-list-item
          routerLink="/logout"
          (click)="drawer.close()">
          <span class='list-item'>Выйти</span>
        </a>
        } @else {<a
          class='list-item'
          mat-list-item routerLink="/login" (click)="drawer.close()">
          <span class='list-item'>Войти</span>
        </a>
        }
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- TOP TOOLBAR -->
      <mat-toolbar class="topbar">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="((handset.isHandset$ | async) || isMobile)"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        @if(router.url==='/create-lamella'){
          <a target="_blank" href="https://vgdoor.ru/">
            <div style="display: inline-block;">
              <img src="../../assets/vgd-logo.png" width="280px" height="20px" style="margin-top: 0px; vertical-align: bottom;">
            </div>
          </a>
        }@else{
          <a class="logo" routerLink="/about">
            <div style="display: inline-block;">
              <img src="../../assets/logo.svg" width="60px" height="60px" style="margin-top: 0px; vertical-align: bottom;">
            </div>
            <!-- <div style="display: inline-block; color: white; margin-left: 10px; vertical-align:baseline; padding-bottom: 14px;">ПЕРВАЯ ФАБРИКА <br>ФАСАДОВ</div> -->
            <div style="display: inline-block; color: white; margin-left: 10px; vertical-align:baseline; padding-bottom: 14px;">Первая Фабрика<br>Фасадов</div>
          </a>
        }
        @if(isCompany || isAuthorizedManager){
          <div class="button-container">
            <a class="button points points2" mat-button routerLink="/points">Ваши бонусы: {{points}}</a>
            <a class="button points discount">Ваша скидка: {{discount}}%</a>
          </div>
        }
        <span class="fill-space"></span>
        <div *ngIf="!(handset.isHandset$ | async) && !isMobile">

          <button mat-button [matMenuTriggerFor]="orderMenu" style="color: white;">Сделать заказ</button>
          <mat-menu #orderMenu="matMenu">
            <button mat-menu-item routerLink="/create" (click)="drawer.close()">
              <span class='list-item' style="color: black;">Фасады</span>
            </button>
            <button mat-menu-item routerLink="/create-lamella" (click)="drawer.close()">
              <span class='list-item' style="color: black;">Рейки</span>
            </button>
          </mat-menu>
          <a class="button" mat-button routerLink="/list">Журнал заказов</a>
          <a class="button" mat-button routerLink="/support">Наши контакты</a>
          @if(isAuthorizedManager){<a class="button" mat-button routerLink="/clients"
            >Клиенты</a
          >} @if (isAuthorizedOwner) {<a class="button" mat-button routerLink="/cabinet"
            >Кабинет</a
          >}
          <a class="button" mat-button routerLink="/lk">Личный кабинет</a>
          @if(isAuthorizedManager){

          }
          @if(isAuthenticated){<a class="button" mat-button routerLink="/logout"
            [ngClass]="{ 'rightmost-button': router.url==='/create' }">
            Выйти</a>}
          @else {
            <a class="button" mat-button routerLink="/login"
            [ngClass]="{ 'rightmost-button': router.url==='/create' }">
              Войти</a>
          }
        </div>
      </mat-toolbar>

      <ng-content></ng-content>

    </mat-sidenav-content>
  </mat-sidenav-container>
  </div>
<div></div>

import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Order } from '../interfaces/order';
import { OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User } from '../interfaces/user';
import { Chart, ChartConfiguration } from 'chart.js/auto';
import { DateService } from '../services/date.service';
import { NumberPipe } from '../pipes/number.pipe';

interface DataPoint{
  x: string;
  y: number;
}
interface DataOfPoints{
  data: DataPoint[];
  label: string;
}
interface ChartData{
  datasets: DataOfPoints[];
}

interface UserOrderAmount{
  [user: string]: Date[];
}

//CHART SETTINGS
Chart.defaults.elements.point.radius = 5;
Chart.defaults.elements.point.hitRadius = 10;
Chart.defaults.plugins.tooltip.titleFont = {size: 18, weight: 'bold'};
Chart.defaults.plugins.tooltip.bodyFont = {size: 18, weight: 'bold'};
Chart.defaults.plugins.legend.labels.font = {size: 24, style: 'italic'};

@Component({
  selector: 'app-graphs',
  standalone: true,
  imports: [CommonModule], // ✅ Add CommonModule
  templateUrl: './graphs.component.html',
  styleUrl: './graphs.component.scss'
})
export class GraphsComponent implements OnInit {

  CHARTS_WEEK_START = '06/01/25';
  CHARTS_MONTH_START = '01/01/25';

  orders: Order[] = [];
  users: User[] = [];
  loadingUsers: boolean = true;
  loadingOrders: boolean = true;
  errorMessage: string = '';

  isDisplaying: boolean = false;

  revenueChartData: ChartData = {
    datasets: [
      {
        data: [],
        label: 'Выручка'
      },
    ]
  };
  ordersAmountChartData: ChartData = {
    datasets: [
      {
        data: [],
        label: "Количество заказов",
      },
    ]
  };
  newClientsChartData: ChartData = {
    datasets: [
      {
        data: [],
        label: "Новые клиенты",
      },
    ]
  };
  recurringClientsChartData: ChartData = {
    datasets: [
      {
        data: [],
        label: "Повторные клиенты",
      },
    ]
  };
  reclamationChartData: ChartData = {
    datasets: [
      {
        data: [],
        label: "Рекламации",
      },
    ]
  };

  usersOrderAmount: UserOrderAmount = {}


  constructor(private apiService: ApiService, private dateService: DateService){}

  ngOnInit(): void {
    this.apiService.getOrderList('').subscribe((data)=>{
      if(data){
        this.orders = data;
        for(let order of data){
          if(!order.created){
            console.error("No created date!");
            continue;
          }
          const date = this.dateService.parseDateFromDB(order.created);
          if(!date){
            console.error("Error parsing data from db");
            continue;
          }
          if(!this.usersOrderAmount[order.phone]){
            this.usersOrderAmount[order.phone] = [];
          }
          this.usersOrderAmount[order.phone].push(date);
        }
        for(let user in this.usersOrderAmount){
          this.usersOrderAmount[user].sort((first, second)=>{
            return +first - +second;
          });
        }
        this.loadingOrders = false;
      }else{
        this.errorMessage = "Произошла ошибка при загрузке заказов";
        return;
      }
    });
    this.apiService.getUserList().subscribe((data)=>{
      if(data){
        this.users = data;
        this.loadingUsers = false;
      }else{
        this.errorMessage = "Произошла ошибка при загрузке пользователей";
        return;
      }
    });
  }

  getCharts() {
    this.isDisplaying = true;
    this.initializeChartsData();
    this.initializeRecurringChartData();
    this.getRevenueChart();
    this.getOrdersAmountChart();
    this.getNewClientsChart();
    this.getReclamationChart();
    this.getRecurringClientsChart();
  }

  @ViewChild('revenueChart') revenueChart!: ElementRef;
  private getRevenueChart(){
    const revenueCtx = this.revenueChart.nativeElement.getContext('2d');
    const cfg = {
      type: 'line',
      data: this.revenueChartData,
      options: {
        plugins: {
          tooltip:{
            callbacks: {
              label: function(context: any){
                return (NumberPipe.prototype.transform(context.parsed.y))+"₽";
              }
            }
          }
        },
        scales: {
          x: {
            ticks:{
              font: {
                size: 18,
              }
            }
          },
          y: {
            suggestedMin: 0,
            ticks: {
              stepSize: 200000,
              font: {
                size: 18
              }
            }
          }
        }
      }
    }
    new Chart(revenueCtx, cfg as unknown as ChartConfiguration);
  }
  @ViewChild('ordersAmountChart') ordersAmountChart!: ElementRef;
  private getOrdersAmountChart(){
    const ordersAmountCtx = this.ordersAmountChart.nativeElement.getContext('2d');
    const cfg = {
      type: 'line',
      data: this.ordersAmountChartData,
      options: {
        scales: {
          x: {
            ticks:{
              font: {
                size: 18,
              }
            }
          },
          y: {
            suggestedMin: 0,
            ticks: {
              stepSize: 4,
              font: {
                size: 18
              }
            }
          }
        }
      }
    }
    new Chart(ordersAmountCtx, cfg as unknown as ChartConfiguration);
  }

  @ViewChild('newClientsChart') newClientsChart!: ElementRef;
  private getNewClientsChart(){
    const newClientsCtx = this.newClientsChart.nativeElement.getContext('2d');
    const cfg = {
      type: 'line',
      data: this.newClientsChartData,
      options: {
        scales: {
          x: {
            ticks:{
              font: {
                size: 18,
              }
            }
          },
          y: {
            suggestedMin: 0,
            ticks: {
              stepSize: 1,
              font: {
                size: 18
              }
            }
          }
        }
      }
    }
    new Chart(newClientsCtx, cfg as unknown as ChartConfiguration);
  }

  @ViewChild('reclamationChart') reclamationChart!: ElementRef;
  private getReclamationChart(){
    const reclamationCtx = this.reclamationChart.nativeElement.getContext('2d');
    const cfg = {
      type: 'line',
      data: this.reclamationChartData,
      options: {
        scales: {
          x: {
            ticks:{
              font: {
                size: 18,
              }
            }
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 3,
            ticks: {
              stepSize: 1,
              font: {
                size: 18
              }
            }
          }
        }
      }
    }
    new Chart(reclamationCtx, cfg as unknown as ChartConfiguration);
  }

  @ViewChild('recurringClientsChart') recurringClientsChart!: ElementRef;
  private getRecurringClientsChart(){
    const recurringClientsCtx = this.recurringClientsChart.nativeElement.getContext('2d');
    const cfg = {
      type: 'line',
      data: this.recurringClientsChartData,
      options: {
        scales: {
          x: {
            ticks:{
              font: {
                size: 18,
              }
            }
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 6,
            ticks: {
              stepSize: 2,
              font: {
                size: 18
              }
            }
          }
        }
      }
    }
    new Chart(recurringClientsCtx, cfg as unknown as ChartConfiguration);
  }

  private initializeChartsData(){
    const currentDate = new Date();
    let weekDate;

    const CHARTS_WEEK_START = this.dateService.parseDateFromDB(this.CHARTS_WEEK_START);

    if(CHARTS_WEEK_START === undefined){
      console.error('Error while parsing CHARTS_WEEK_START');
      return;
    }else{
      weekDate = this.dateService.parseDateFromDB(this.CHARTS_WEEK_START);
    }
    //typescript bullshittery
    if(!weekDate){
      return;
    }

    while(currentDate >= weekDate){
      this.revenueChartData.datasets[0].data.push({x:this.dateService.parseDateToDB(weekDate),y: 0})
      this.ordersAmountChartData.datasets[0].data.push({x:this.dateService.parseDateToDB(weekDate),y: 0})
      this.newClientsChartData.datasets[0].data.push({x:this.dateService.parseDateToDB(weekDate),y: 0})
      this.reclamationChartData.datasets[0].data.push({x:this.dateService.parseDateToDB(weekDate),y: 0})
      weekDate.setDate(weekDate.getDate()+7);
    }
    for(let order of this.orders){
      if(!order.created){
        console.error("Order has no created date!");
        continue;
      }
      const createdDate = this.dateService.parseDateFromDB(order.created);
      if(!createdDate){
        console.error("Error parsing date from DB");
        continue;
      }
      const daysPassed = this.dateService.getDaysPassed(CHARTS_WEEK_START, createdDate);
      if(daysPassed < 0){
        continue;
      }
      if(['pending-manufacturer','manufacturing', 'warehouse', 'shipped'].includes(order.status)){
        this.revenueChartData.datasets[0].data[Math.floor(daysPassed/7)].y += order.totalPriceMarkup!;
        this.ordersAmountChartData.datasets[0].data[Math.floor(daysPassed/7)].y += 1;
      }
      //TODO reclamations
      // if(order.reclamation === true){
      //   this.reclamationChartData.datasets[0].data[Math.floor(daysPassed/7)].y += 1;
      // }
    }
    for(let user in this.usersOrderAmount){
      const date = this.usersOrderAmount[user][0];
      const daysPassed = this.dateService.getDaysPassed(CHARTS_WEEK_START, date);
      if(daysPassed < 0){
        continue;
      }
      this.newClientsChartData.datasets[0].data[Math.floor(daysPassed/7)].y += 1;
    }
  }

  private initializeRecurringChartData(){
    const currentDate = new Date();
    let monthDate;

    const CHARTS_MONTH_START = this.dateService.parseDateFromDB(this.CHARTS_MONTH_START);

    if(CHARTS_MONTH_START === undefined){
      console.error('Error while parsing CHARTS_WEEK_START');
      return;
    }else{
      monthDate = this.dateService.parseDateFromDB(this.CHARTS_MONTH_START);
    }
    //typescript bullshittery
    if(!monthDate){
      return;
    }
    //count index from beggining as ChartData does not support string indexation
    let counter = 0;
    while(currentDate >= monthDate){
      this.recurringClientsChartData.datasets[0].data.push({x: this.dateService.parseDateToDB(monthDate), y: 0})

      const currentMonth = monthDate.getMonth();
      let previousMonthDate = new Date(monthDate);
      previousMonthDate.setMonth(previousMonthDate.getMonth()-1);
      const previousMonth = previousMonthDate.getMonth();

      for(let user in this.usersOrderAmount){

        let isOrderInCurrentMonth = false;
        let isOrderInPreviousMonth = false;

        for(let orderDate of this.usersOrderAmount[user]){
          const month = orderDate.getMonth();
          if(!isOrderInCurrentMonth && month === currentMonth){
            isOrderInCurrentMonth = true;
          }
          if(!isOrderInPreviousMonth && month === previousMonth){
            isOrderInPreviousMonth = true;
          }
          if(isOrderInCurrentMonth && isOrderInPreviousMonth){
            break;
          }
        }
        if(isOrderInCurrentMonth && isOrderInPreviousMonth){
          this.recurringClientsChartData.datasets[0].data[counter].y += 1;
        }
      }
      monthDate.setMonth(monthDate.getMonth()+1);
      counter++;
    }

  }

}
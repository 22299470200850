import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  MAP_STATUS: { [key: string]: string } = {
    'cancelled': 'Отменён',
    'saved': 'Сохранён',
    'review': 'В обработке у менеджера',
    'pending': 'Ожидает оплаты',
    'pending-manufacturer': 'Изготавливается',
    'manufacturing': 'Изготавливается',
    'warehouse': 'На складе',
    'shipped': 'Отгружен',
  };

  MAP_TO_RUS: { [key: string]: string } = {
    'typeF': 'Тип фасада',
    'thickness': 'Толщина',
    'freza': 'Обкатная фреза',
    'milling': 'Фрезеровка',
    'ispol': 'Вид фасада',
    'decor': 'Декор',
    'texture': 'Текстура',
    'star': 'Старение',
    'polish': 'Лак',
    'collect': 'Коллекция',
    'kromka': 'Кромка',
    'kromkaDesign': 'Дизайн кромки',
    'height': 'Высота',
    'width': 'Ширина',
    'amount': 'Кол-во',
    'area': 'Sдет',
    'totalArea': 'Sобщ',
    'price': 'Цена РРЦ',
    'priceCalc': 'Сумма РРЦ',
    'priceMarkup': 'Сумма РРЦ с %',
  };
  jsonToCSV(jsonData: any[], orderInfo: any[], delimiter: string): string {
    const csvRows: string[] = [];
    const headers = Object.keys(jsonData[0]).filter(key => key !== '_id');;
    csvRows.push("\uFEFF");
    csvRows.push(`Создан${delimiter}Дата оплаты${delimiter}План-дата поступления на склад${delimiter}Факт-дата поступления на склад${delimiter}Номер${delimiter}Статус${delimiter}Сумма РРЦ${delimiter}Сумма РРЦ с %${delimiter}Оптовая сумма${delimiter}Лицо${delimiter}ФИО${delimiter}Sобщая${delimiter}Ответственный`);
    let orderHead = ''; //Информация о заказе
    for(const elem of orderInfo){
      orderHead += !elem ? `----${delimiter}` : elem+`${delimiter}`;
    }
    // csvRows.push(orderInfo.toString()+'\n');
    csvRows.push(orderHead+'\n');
    let header = '№'; // Информация о позициях
    for (const element of headers){
      header+=`${delimiter}${this.MAP_TO_RUS[element]}`;
    }
    csvRows.push(header);
    // csvRows.push('№,'+headers.toString());
    // csvRows.push('№, Тип фасада, Толщина, Коллекция, Фрезеровка, Обкатная фреза, Декор, Текстура, Старение, Вид фасада, Высота, Ширина, Кол-во, S, Sобщ, Цена РРЦ, Сумма РРЦ, Сумма РРЦ с %');
    let index = 1;
    for (const row of jsonData) {
      const values = headers.map(header => {
        const value = row[header];
        const escaped = (value === null || value === undefined || value === '') ? '----' : ('' + value).replace(/"/g, '\\"'); // Check for empty value
        return `"${escaped}"`; // Wrap in quotes
    });
      csvRows.push(index+`${delimiter}`+values.join(`${delimiter}`));
      index++;
    }

    return csvRows.join('\n'); // Join rows with new line
  }

//   <style>
// .Head { background-color:gray; color:white; }
// .Red  { color:red; }
// </style>
// <table border=1>
// <tr><td class=Head>Colum1</td><td class=Head>Column2</td></tr>
// <tr><td>Val1</td><td>Val2</td></tr>
// <tr><td class=Red>Авиабилет</td><td class=Red>Tλληνικ</td></tr>
// </table>

//             <tr><td>Colum1</td><td>Column2</td></tr>
//             <tr><td>Val1</td><td>Val2</td></tr>
//             <tr><td>Авиабилет</td><td>Tλληνικ</td></tr>
  jsonToXMLRFM(userRFM: any[]): string {
    let xmlContent: string = '';
    xmlContent += `
    <root>
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
    <head>
      <style>
        td{
          border: 1px solid black;
        }
        .new{
          background-color: #ba98ff;
        }
        .retail{
          background-color: #ecaa40;
        }
        .loyal{
          background-color: rgb(190, 246, 107);
        }
        .risk{
          background-color: rgb(255, 255, 88);
        }
        .loss{
         background-color: rgb(255, 109, 109);
        }
      </style>
        <meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
        <xml>
            <x:ExcelWorkbook>
                <x:ExcelWorksheets>
                    <x:ExcelWorksheet>
                        <x:Name>RFM</x:Name>
                        <x:WorksheetOptions>
                            <x:DisplayGridlines/>
                        </x:WorksheetOptions>
                    </x:ExcelWorksheet>
                </x:ExcelWorksheets>
            </x:ExcelWorkbook>
        </xml>
    </head>
    <body>
        <table>
        <tr>
          <th>ID</th>
          <th>Телефон</th>
          <th>ФИО</th>
          <th>Заказы за месяц</th>
          <th>Заказы за квартал</th>
          <th>Выкуп за месяц</th>
          <th>Выкуп за квартал</th>
          <th>Статус</th>
        </tr>`;
    for(let user of userRFM){
      let myClass = '';
      switch(user.status){
        case "new":
          myClass = `class="new"`
          break;
        case "retail":
          myClass = `class="retail"`
          break;
        case "loyal":
          myClass = `class="loyal"`
          break;
        case "risk":
          myClass = `class="risk"`
          break;
        case "loss":
          myClass = `class="loss"`
          break;

      }
      xmlContent +=
       `
       <tr>
       <td ${myClass}>${user.bitrixID}</td>
        <td ${myClass}>${user.phone}</td>
        <td ${myClass}>${user.name}</td>
        <td ${myClass}>${user.amountMonth}</td>
        <td ${myClass}>${user.amountQuarter}</td>
        <td ${myClass}>${user.vikupMonth}</td>
        <td ${myClass}>${user.vikupQuarter}</td>
        <td ${myClass}>${user.statusRu}</td>
      </tr>`;
    }
    xmlContent += `
        </table>
      </body>
    </html>
    </root>`;

    return xmlContent;
  }

  jsonToXMLRFM2(userRFM: any[]): string {
    let xmlContent: string = '';
    xmlContent += `
    <root>
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
    <head>
      <style>
        td{
          border: 1px solid black;
        }
      </style>
        <meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
        <xml>
            <x:ExcelWorkbook>
                <x:ExcelWorksheets>
                    <x:ExcelWorksheet>
                        <x:Name>RFM</x:Name>
                        <x:WorksheetOptions>
                            <x:DisplayGridlines/>
                        </x:WorksheetOptions>
                    </x:ExcelWorksheet>
                </x:ExcelWorksheets>
            </x:ExcelWorkbook>
        </xml>
    </head>
    <body>
        <table>
        <tr>
          <th>ФИО</th>
          <th>Заказы за месяц</th>
          <th>Выкуп за месяц</th>
        </tr>`;
    for(let user of userRFM){
      xmlContent +=
       `
       <tr>
       <td >${user.name}</td>
       <td >${user.amountThisMonth}</td>
       <td >${user.vikupThisMonth}</td>
      </tr>`;
    }
    xmlContent += `
        </table>
      </body>
    </html>
    </root>`;

    return xmlContent;
  }

  jsonToCSVOrders(orderData: any[], startDate: string, endDate: string, delimiter: string): string {
    const csvRows: string[] = [];
    csvRows.push("\uFEFF");

    // let orderHead = '';
    // for (const elem of orderInfo) {
    //   orderHead += !elem ? `----${delimiter}` : elem + `${delimiter}`;
    // }
    // csvRows.push(orderHead + '\n');

    csvRows.push(`Начало промежутка${delimiter}${startDate}`);
    csvRows.push(`Конец промежутка${delimiter}${endDate}`);
    csvRows.push(`Создан${delimiter}ФИО${delimiter}Номер${delimiter}Статус${delimiter}Оптовая сумма${delimiter}`);

    const headers = ['created', 'name', 'customId', 'mappedStatus', 'totalPriceMarkup'];

    // let header = '№';
    // for (const headerName of headers) {
    //   header += `${delimiter}${this.MAP_TO_RUS[headerName] || headerName}`;
    // }
    // csvRows.push(header);

    for (const order of orderData) {
      const values = headers.map(header => {
        const value = order[header];
        const escaped = (value === null || value === undefined || value === '') ? '----' : ('' + value).replace(/"/g, '\\"');

        return `"${escaped}"`;
      });
      csvRows.push(values.join(`${delimiter}`));
    }
    return csvRows.join('\n');
  }

  downloadCSV(csvContent: string, filename: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadXML(xmlContent: string): void {
    const blob = new Blob([xmlContent], { type: 'text/xml;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    console.log(`${new Date()}`)
   link.setAttribute('href', url);
    link.setAttribute('download', "data.xls");
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

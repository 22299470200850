import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  //get locale date or undefined if not valid date for pattern DD/MM/YY
  parseDateFromDB(value: string): Date | undefined{
    const date = value.split("/");
    if(date.length !== 3){
      console.error('Length of date is not in format of DD/MM/YY');
      return undefined;
    }
    let year = parseInt(date[2], 10);
    const month = parseInt(date[1], 10) - 1;
    const day = parseInt(date[0], 10);

    if (Number.isNaN(year) || year < 0 || year > 99 ){
      console.error('Error parsing year');
      return undefined;
    }
    if (Number.isNaN(month) || month < 0 || month > 12){
      console.error('Error parsing month');
      return undefined;
    }
    if (Number.isNaN(day) || day < 0 || day > 31){
      console.error('Error parsing day');
      return undefined;
    } 
    //reassign to use 20xx years instead of auto 19xx
    year = parseInt("20" + year.toString(), 10);
    return new Date(year, month, day);
  }

  parseDateToDB(value: Date): string {
    const date = value.toLocaleString("fr-FR").split(" ")[0].split("/")
    return date[0]+"/"+date[1]+"/"+date[2].substring(2)
  }

  //Get how many days have passed from date1 to date2
  getDaysPassed(date1: Date, date2: Date): number{
    //1000*60*60*24
    return Math.round((+date2 - +date1)/8.64e7);
  }

}

@if(handsetService.isHandset$ | async){
  <div class="page bg" [ngClass]="{'loading-cursor': loading}">
    <div class="form-border">
      <div>
        <span>
          <h1>
            <span class="text-register-mobile">Регистрация</span> <div class="error-message-mobile">{{ errorMessage }}</div>
          </h1>
        </span>
      </div>
      <div>
        <form [formGroup]="account" (ngSubmit)="onSubmit()">
          <div>
            <input class="form-input-mobile"
              formControlName="name"
              placeholder="ФИО"
              maxlength="50"
            />
            <input
              class="form-input-mobile"
              formControlName="phone"
              placeholder="Телефон (89121234567)"
              maxlength="15"
            />
            <input
              class="form-input-mobile"
              formControlName="mail"
              placeholder="E-mail"
              maxlength="60"
            />
          </div>
          <div>
            <select class="form-select-mobile" formControlName="person">
              <option value="Розничный покупатель" selected>Розничный покупатель</option>
              <option value="Дизайнер интерьера">Дизайнер интерьера</option>
              <option value="Производитель мебели">Производитель мебели</option>
              <!-- <option value="Сотрудник производителя мебели">Сотрудник производителя мебели</option> -->
            </select>
            @if (account.value.person !== 'Розничный покупатель' && account.value.person !== "Сотрудник производителя мебели") {
              <select
                class="form-select-mobile form-select-short-mobile"
                formControlName="company"
              >
                <option value="ИП" selected>ИП</option>
                <option value="ООО">ООО</option>
                <option value="Самозанятый">Самозанятый</option>
                <option value="Физическое лицо">Физ. лицо</option>
              </select>
              <input
                class="form-input-mobile"
                formControlName="companyName"
                placeholder="Название компании"
              />
              @if(account.value.company !== 'Физическое лицо'){
                <input class="form-input-mobile" formControlName="inn" placeholder="ИНН (без тире)" />
              }
            }
            @if(account.value.person == "Сотрудник производителя мебели"){
              <!-- <select
                class="form-select-mobile"
                formControlName="company"
                style="visibility: hidden;"
              >
                <option value="ИП" selected>ИП</option>
                <option value="ООО">ООО</option>
                <option value="Самозанятый">Самозанятый</option>
                <option value="Физическое лицо">Физ. лицо</option>
              </select> -->
              <input
                class="form-input-mobile"
                formControlName="parentID"
                placeholder="ID руководителя"
                maxlength="30"
              />
            }
          </div>
          <!-- <input
              class="form-input-mobile"
              formControlName="managerConfirmationID"
              placeholder="ID менеджера (необязательно)"
              maxlength="6"
            /> -->
          <div>
            <input
              class="form-input-mobile"
              formControlName="password"
              placeholder="Пароль"
              type="password"
              maxlength="30"
            />
            <input
              class="form-input-mobile"
              formControlName="passwordCheck"
              placeholder="Повторите пароль"
              type="password"
              maxlength="30"
            />
          </div>
          <div>
            <button class="form-button-mobile register-button-mobile" type="submit" [disabled]="loading">Зарегистрироваться</button>
            <a routerLink="/login">
              <button class="form-button-mobile login-button-mobile" [disabled]="loading">Обратно</button>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
} @else {
  <div class="page bg" [ngClass]="{'loading-cursor': loading}">
    <div class="form-border">
      <div>
        <span>
          <h1>
            <span class="text-register">Регистрация</span> <span class="error-message">{{ errorMessage }}</span>
          </h1>
        </span>
      </div>
      <div>
        <form [formGroup]="account" (ngSubmit)="onSubmit()">
          <div>
            <input class="form-input"
              formControlName="name"
              placeholder="ФИО"
              maxlength="50"
            />
            <input
              class="form-input"
              formControlName="phone"
              placeholder="Телефон (89121234567)"
              maxlength="15"
            />
            <input
              class="form-input"
              formControlName="mail"
              placeholder="E-mail"
              maxlength="60"
            />
          </div>
          <div>
            <select class="form-select" formControlName="person">
              <option value="Розничный покупатель" selected>Розничный покупатель</option>
              <option value="Дизайнер интерьера">Дизайнер интерьера</option>
              <option value="Производитель мебели">Производитель мебели</option>
              <!-- <option value="Сотрудник производителя мебели">Сотрудник производителя мебели</option> -->
            </select>
            @if (account.value.person !== 'Розничный покупатель' && account.value.person !== "Сотрудник производителя мебели") {
              <select
                class="form-select form-select-short"
                formControlName="company"
              >
                <option value="ИП" selected>ИП</option>
                <option value="ООО">ООО</option>
                <option value="Самозанятый">Самозанятый</option>
                <option value="Физическое лицо">Физ. лицо</option>
              </select>
              <input
                class="form-input"
                formControlName="companyName"
                placeholder="Название компании"
              />
              @if(account.value.company !== 'Физическое лицо'){
                <input class="form-input" formControlName="inn" placeholder="ИНН (без тире)" />
              }
            }
            @if(account.value.person == "Сотрудник производителя мебели"){
              <select
                class="form-select form-select-short"
                formControlName="company"
                style="visibility: hidden;"
              >
                <option value="ИП" selected>ИП</option>
                <option value="ООО">ООО</option>
                <option value="Самозанятый">Самозанятый</option>
                <option value="Физическое лицо">Физ. лицо</option>
              </select>
              <input
                class="form-input"
                formControlName="parentID"
                placeholder="ID руководителя"
                maxlength="30"
              />
            }
          </div>
          <div>
            <form class="example-form">
                <input #subjectHTML
                  type="text"
                  placeholder="Субъект РФ"
                  matInput
                  [formControl]="myControlSubject"
                  [matAutocomplete]="auto"
                  [(ngModel)]="subject"
                  (input)="filterPVC()"
                  (focus)="filterPVC()"
                  class="filter-input form-input"
                >
                <mat-autocomplete requireSelection #auto="matAutocomplete" >
                  @for (option of filteredSubjects; track option) {
                    <mat-option [value]="option">{{option}}</mat-option>
                  }
                </mat-autocomplete>
            </form>
          </div>
          <div>
            <input
              class="form-input"
              formControlName="password"
              placeholder="Пароль"
              type="password"
              maxlength="30"
            />
            <input
              class="form-input"
              formControlName="passwordCheck"
              placeholder="Повторите пароль"
              type="password"
              maxlength="30"
            />
            <!-- <input
              class="form-input"
              formControlName="managerConfirmationID"
              placeholder="ID менеджера (необязательно)"
              maxlength="6"
            /> -->
          </div>
          <!-- <div>
            <button class="form-button register-button" type="submit">Зарегистрироваться</button>
            <a routerLink="/login">
              <button class="form-button login-button">Логин</button>
            </a>
            <div class="pd">
              <span class="pd-text">Регистрируясь, вы соглашаетесь c политикой <br/> обработки персональных данных </span>
            </div>
          </div> -->
          <div class="button-container">
            <button class="form-button register-button" type="submit" [disabled]="loading">Зарегистрироваться</button>
            <a routerLink="/login">
              <button class="form-button login-button" [disabled]="loading">Обратно</button>
            </a>
            <div class="pd">
              <span class="pd-text">Регистрируясь, вы соглашаетесь c <a href="/pd" class="pd-link" target="_blank">политикой <br/> обработки персональных данных</a> </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}
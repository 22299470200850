<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if (loading || errorMessage) {
    <h2>Загрузка данных</h2>
    <h2>{{errorMessage}}</h2>
  } @else{  
  <div style="margin-bottom: 20px;">
    <table id="first">
      <tr class="first">
        <th colspan="2" class="loyal" style="width: 33%;">
          @for(user of users; track $index){
            @if(user.status === 'loyal'){
              <span class="jump" (click)="jump(user.crmId.toString())">{{user.crmId}}</span>&nbsp;
            }
          }
        </th>
        <th colspan="2" class="risk" style="width: 33%;">
          @for(user of users; track $index){
            @if(user.status === 'risk'){
              <span class="jump" (click)="jump(user.crmId.toString())">{{user.crmId}}</span>&nbsp;
            }
          }
        </th>
        <th colspan="2" class="loss" style="width: 34%;">
          @for(user of users; track $index){
            @if(user.status === 'loss'){
              <span class="jump" (click)="jump(user.crmId.toString())">{{user.crmId}}</span>&nbsp;
            }
          }
        </th>
      </tr>
      <tr class="first">
        <th colspan="3" class="new" style="width: 50%;">
          @for(user of users; track $index){
            @if(user.status === 'new'){
              <span class="jump" (click)="jump(user.crmId.toString())">{{user.crmId}}</span>&nbsp;
            }
          }
        </th>
        <th colspan="3" class="retail" style="width: 50%;">
          @for(user of users; track $index){
            @if(user.status === 'retail'){
              <span class="jump" (click)="jump(user.crmId.toString())">{{user.crmId}}</span>&nbsp;
            }
          }
        </th>
      </tr>
    </table>
  </div>
  <input class="search" type="text" [(ngModel)]="searchWord" (input)="search()" placeholder="Поиск" onclick="this.select()"> 
  
  <button class="button export-button" (click)="exportToXML()"> Скачать RFM</button>
  <button class="button export-button" (click)="exportToXML2()"> Скачать отчёт</button>
  <input class='delimiter' type="checkbox" id="delimiter" name="delimiter" [(ngModel)]="delimiter"/>
  <label class='delimiter'  for="delimiter">Сменить разделитель (excel)</label>
  
  <div class="border-radius second">
    <table class="second">
      <thead class="second">
        <tr class="second">
          <th class="second">№</th> 
          <th (click)="sort('crmId')" class="second">
            <span style="text-wrap: nowrap;">
              ID <i class="fa" [ngClass]="{'fa-sort': sortField !== 'crmId', 'fa-sort-up': sortField === 'crmId' && sortOrder === 'asc', 'fa-sort-down': sortField === 'crmId' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th class="second">Телефон</th>
          <th (click)="sort('name')" class="second">
            <span style="text-wrap: nowrap;">
              ФИО <i class="fa" [ngClass]="{'fa-sort': sortField !== 'name', 'fa-sort-up': sortField === 'name' && sortOrder === 'asc', 'fa-sort-down': sortField === 'name' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('amountMonth')" class="second">
            <span style="text-wrap: nowrap;">
              Заказы за месяц <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amountMonth', 'fa-sort-up': sortField === 'amountMonth' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amountMonth' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('amountQuarter')" class="second">
            <span style="text-wrap: nowrap;">
              Заказы за квартал <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amountQuarter', 'fa-sort-up': sortField === 'amountQuarter' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amountQuarter' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          
          <th (click)="sort('vikupMonth')" class="second">
            <span style="text-wrap: nowrap;">
              Выкуп за месяц <i class="fa" [ngClass]="{'fa-sort': sortField !== 'vikupMonth', 'fa-sort-up': sortField === 'vikupMonth' && sortOrder === 'asc', 'fa-sort-down': sortField === 'vikupMonth' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('vikupQuarter')" class="second">
            <span style="text-wrap: nowrap;">
              Выкуп за квартал <i class="fa" [ngClass]="{'fa-sort': sortField !== 'vikupQuarter', 'fa-sort-up': sortField === 'vikupQuarter' && sortOrder === 'asc', 'fa-sort-down': sortField === 'vikupQuarter' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('statusRu')" class="second">
            <span style="text-wrap: nowrap;">
              Статус <i class="fa" [ngClass]="{'fa-sort': sortField !== 'statusRu', 'fa-sort-up': sortField === 'statusRu' && sortOrder === 'asc', 'fa-sort-down': sortField === 'statusRu' && sortOrder === 'desc'}"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (user of usersSorted; track $index) {
        <tr id="{{user.crmId}}" [ngClass]="{'new': user.status === 'new','retail': user.status === 'retail', 'loyal': user.status ==='loyal', 'risk': user.status ==='risk', 'loss': user.status ==='loss'}" [ngClass]="{'highlight': highlightedID === user.crmId.toString()}" class="second">
          <td class="second">{{ $index+1 }}</td>
          <td class="second">
            @if(user.isRetail){
              <a href="https://ihouse.bitrix24.ru/crm/contact/details/{{user.crmId}}/">
                {{ user.crmId }}
              </a>
            } @else {
              <a href="https://ihouse.bitrix24.ru/crm/company/details/{{user.crmId}}/">
                {{ user.crmId }}
              </a>
            }
          </td>
          <td class="second">{{ user.phone }}</td>
          <td class="second">{{ user.name }}</td>
          <td class="second">{{ user.amountMonth }}</td>
          <td class="second">{{ user.amountQuarter }}</td>
          <td class="second">{{ user.vikupMonth | number}}</td>
          <td class="second">{{ user.vikupQuarter | number}}</td>
          <td class="second">{{ user.statusRu }}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  }
  <button class="floating-button" (click)="jump('first')">&#8593;</button> 
</div>

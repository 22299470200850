import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { jwtDecode } from 'jwt-decode';

import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '../interfaces/user';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private apiService: ApiService,
    private router: Router
  ) {}
  private refreshing: boolean = false;
  private pendingRequests: Array<() => void> = [];

  isAuthenticatedVar: boolean = false;

  isAuthenticated(): boolean {
    return !!localStorage.getItem('session');
  }

  setOrderGuardCheck(): void{
    localStorage.setItem('orderGuardCheck', '1');
  }

  getOrderGuardCheck(): boolean{
    return localStorage.getItem('orderGuardCheck') === '1';
  }
 
  removeOrderGuardCheck(): void{
    localStorage.removeItem('orderGuardCheck');
  }
  
  setCabinetGuardCheck(): void{
    localStorage.setItem('cabinetGuardCheck', '1');
  }

  getCabinetGuardCheck(): boolean{
    return localStorage.getItem('cabinetGuardCheck') === '1';
  }

  removeCabinetGuardCheck(): void{
    localStorage.removeItem('cabinetGuardCheck');
  }

  logout() {
    localStorage.removeItem('session');
    // this.cookieService.deleteAll('/', environment.domain);
    localStorage.removeItem('mobile');
    this.apiService.logout().subscribe((data)=>{
      this.cookieService.delete('Authorization', '/ ',  environment.domain, true, 'Lax');
    });
    this.router.navigate(['/login']);
  }
  setCookie(data: any): void {
    if (typeof data === 'object' && 'accessToken' in data) {
      this.cookieService.set('Authorization', data.accessToken, {
        // expires: (1 / 24 / 60 / 60) * 60 * 60 * 24 * 7,
        // expires: (1 / 24 / 60 / 60) * 120,
        expires: (1 / 24 / 60 / 60) * 15 ,
        secure: true,
        path: '/',
      });
    }
  } 

  setCookieCreatedOrder(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('createdOrder', data._id, {
        expires: (1 / 24 / 60 / 60) * 15,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieCreatedOrder(): string {
    if(!this.cookieService.get('createdOrder')){
      return '';
    } else {
      return this.cookieService.get('createdOrder');
    }
  }

  setCookieDeletedOrder(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('deletedOrder', data._id, {
        expires: (1 / 24 / 60 / 60) * 15,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieDeletedOrder(): string {
    if(!this.cookieService.get('deletedOrder')){
      return '';
    } else {
      return this.cookieService.get('deletedOrder');
    }
  }
  setCookieDeletedUser(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('deletedUser', data._id, {
        expires: (1 / 24 / 60 / 60) * 5,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieDeletedUser(): string {
    if(!this.cookieService.get('deletedUser')){
      return '';
    } else {
      return this.cookieService.get('deletedUser');
    }
  }
  //TODO set in localstorage and check for Cookie will soon be rejected because it is foreign and does not have the “Partitioned“ attribute.
  //PASS IN COOKIE FROM TOKEN BECAUSE OTHERWISE CAN BREACH
  //jwtdecode on server side

  refreshCookie(): void{
    this.cookieService.delete('Authorization');
    this.getCookie().subscribe(()=>{});
  }

  getCookie(): Observable<User>{
    const cookie = this.cookieService.get('Authorization');
    if(cookie){
      const decoded = jwtDecode(cookie) as { UserInfo?: User }; // Replace 'any' with the actual type if known
      if (decoded.UserInfo) {
        return of(decoded.UserInfo);
      }else{
        throw Error('Decoded JWT token does not contain UserInfo');
      }
    }else{
      return new Observable<User>((observer) => {
        this.apiService.refresh().subscribe({
          next: (data) => {
            this.setCookie(data);
            const decoded = jwtDecode(data.accessToken) as { UserInfo?: User }; // Replace 'any' with the actual type if known
            if (decoded && decoded.UserInfo) {
              observer.next(decoded.UserInfo); // Emit the new cookie
            }else{
              throw Error('Decoded JWT token does not contain UserInfo');
            }
            observer.complete();
          },
          error: (error) => {
            observer.error(error); // Handle error
          }
        });
      });
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateColhoz',
  standalone: true
})
export class DateColhozPipe implements PipeTransform {

  transform(value: string | undefined, ...args: unknown[]): unknown {
    if(!value){
      return value;
    }
    return value.replaceAll('/', '.');
  }

} 
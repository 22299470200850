@if(!(handset.isHandset$ | async)){ <!-- if device is NOT mobile -->
  <div class="page bg" [ngClass]="{'loading-cursor': loading}">
    <div class="form-border">
      <div>
        <span>
          <h1 class="text-enter">Вход</h1>
          <p class="error-message">{{ errorMessage }}</p>
        </span>
      </div>
      <div>
        <form [formGroup]="account" (ngSubmit)="onSubmit()">
          <div>
            <input
              class="form-input"
              formControlName="phone"
              placeholder="Телефон (89821234567)"
              maxlength="15"
            />
          </div>
          <div>
            <input
              class="form-input"
              formControlName="password"
              placeholder="Пароль"
              type="password"
              maxlength="30"
            />
          </div>
          <div>
            <button class="form-button login-button" type="submit" [disabled]="loading">Войти</button>
            <a routerLink="/register">
              <button class="form-button register-button" type="button" [disabled]="loading">
                <span class="register-text">Регистрация</span>
              </button>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
} @else { <!-- if device is mobile -->
  <div class="page-mobile bg" [ngClass]="{'loading-cursor': loading}">
    <div class="form-border-mobile">
      <div class="form-header-mobile">
        <span>
          <h1 class="text-enter-mobile">Вход</h1>
          <p class="error-message-mobile">{{ errorMessage }}</p>
        </span>
      </div>
      <div class="form-group-mobile">
        <form [formGroup]="account" (ngSubmit)="onSubmit()">
          <div>
            <input
              class="form-input-mobile"
              formControlName="phone"
              placeholder="Телефон (89001234567)"
              maxlength="15"
            />
          </div>
          <div>
            <input
              class="form-input-mobile"
              formControlName="password"
              placeholder="Пароль"
              type="password"
              maxlength="30"
            />
          </div>
          <div class="form-group-buttons-mobile">
            <button class="form-button-mobile login-button-mobile" type="submit" [disabled]="loading">Войти</button>
            <a routerLink="/register">
              <button class="form-button-mobile register-button-mobile" type="button" [disabled]="loading">
                <span class="register-text-mobile">Регистрация</span>
              </button>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
}
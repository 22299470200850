import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor() { }

  isClient(role: string): boolean {
    return role === 'client';
  }

  isManager(role: string): boolean {
    return role === 'manager';
  }
  
  isProvision(role: string): boolean {
    return role === 'provision';
  }
  
  isOwner(role: string): boolean {
    return role === 'owner';
  }

  isAdmin(role: string): boolean {
    return role === 'admin';
  }

  isAtLeastManager(role: string): boolean {
    return ['manager', 'provision', 'owner', 'admin'].includes(role);
  }
  
  isAtLeastProvision(role: string): boolean {
    return ['provision', 'owner', 'admin'].includes(role);
  } 

  isAtLeastOwner(role: string): boolean {
    return ['owner', 'admin'].includes(role);
  }
}

<div class="page">
  @if(errorMessage){
    <span class="error-message">{{ errorMessage }}</span>
  }
  @if(goodMessage){
    <span class="error-message green">{{ goodMessage }}</span>
  }
  <div class="mainDiv">
    <div class="cardStyle">
      <p class="text">Ваш ID: {{_id}}</p>
      <form [formGroup]="account" (ngSubmit)="onSubmit()">
        <h2 class="formTitle">
          Изменить пароль
        </h2>
        @if(isAuthorizedOwner){
          <div class="inputDiv">
            <label class="inputLabel" for="phone">Телефон</label>
            <input id="phone" formControlName="phone">
          </div>
        }
        <div class="inputDiv">
          <label class="inputLabel" for="password">Новый Пароль</label>
          <input type="password" id="password" name="password" formControlName="password" required>
        </div>
        <div class="inputDiv">
          <label class="inputLabel" for="confirmPassword">Повторите Пароль</label>
          <input type="password" id="confirmPassword" name="confirmPassword" formControlName="passwordRepeat" required>
        </div>
        <div class="buttonWrapper">
          <button type="submit" id="submitButton"  class="submitButton pure-button pure-button-primary">
            <span>Изменить пароль</span>
          </button>
        </div> 
      </form>
    </div>
    <!-- @if(isAuthorizedManager){
      <div class="mainDiv" style="margin-left: 20px;">
        <div class="cardStyle">
          <div class="inputDiv">
            <label class="inputLabel" for="bitrixWorkID">Изменение БитриксID</label>
            <input [(ngModel)]="bitrixWorkID" id="bitrixWorkID"/> 
          </div>
          <div class="buttonWrapper">
            <button class="submitButton pure-button pure-button-primary" (click)="changeBitrixWorkID()">
              <span>Изменить ID</span>
            </button>
          </div>
        </div>
      </div>
    } -->
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number',
  standalone: true
})
export class NumberPipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): unknown {
    
    if (!value){
      return value;
    }
    if(value > 10){
      value = Math.ceil(value);
    }
    let valueStr = value.toString();

    let returnString = valueStr;
    let decimalPart = ''; 
    
    if(valueStr.includes('.')){
      decimalPart = valueStr.substring(valueStr.indexOf('.'));
      returnString = valueStr.substring(0, valueStr.indexOf('.')); 
    }

      let result = '';
      
      for (let i = returnString.length - 1; i >= 0; i--) {
          result = returnString[i] + result;
          if ((returnString.length - i) % 3 === 0 && i !== 0) {
              result = ' ' + result;
          }
      }
      
      return result + decimalPart; 
    }
  
  // Example usage
  // let number = 1234567890;
  // let groupedNumber = groupByThree(number);
  // console.log(groupedNumber); // Output: "1,234,567,890"

}

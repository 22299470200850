<!DOCTYPE html>  
<html>
<head>
  <meta charset="UTF-8">
  <title>Тех.поддержка</title>
</head>
<body>
  <div class="white-page">
      <h2>Техподдержка</h2>
      <p>
        В случае некорректной работы сайта, при возникновении неполадок или ошибок, для помощи при входе в аккаунт, обращайтесь на электронную почту
        <a href="mailto:info@fabfas74.ru">info&#64;fabfas74.ru</a>.
      </p>
      <h2>Контакты</h2>
      <div class="links">
        <a href="mailto:info@fabfas74.ru">info&#64;fabfas74.ru</a>
        <a href="tel:89227204060" class="phone">+7 (922) 72-040-60</a>
      </div>
  </div>
</body>
</html> 